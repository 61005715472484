import React from 'react'
import DigitalSecurityBanner from '../components/Services/DigitalSecurityBanner'
import DigitalSecurityRow from '../components/Services/DigitalSecurityRow'
import DigitalSecurityAttached from '../components/Services/DigitalSecurityAttached'
import DigitalSecuritySecurity from '../components/Services/DigitalSecuritySecurity'
import DigitalSecurityRisk from '../components/Services/DigitalSecurityRisk'
import DigitalSecurityGetTouch from '../components/Services/DigitalSecurityGetTouch'
import DigitalSecuritySlide from '../components/Services/DigitalSecuritySlide'
import DigitalSecurityReadMore from '../components/Services/DigitalSecurityReadMore'

const DigitalSecurity = () => {
    return (
        <div id='digital-security-container' className='p-xl-0 p-3'>
            <DigitalSecurityBanner />
            <DigitalSecurityRow />
            <DigitalSecurityAttached />
            <DigitalSecuritySecurity />
            <DigitalSecurityRisk />
            <DigitalSecurityGetTouch />
            <DigitalSecuritySlide />
            <DigitalSecurityReadMore />
        </div>
    )
}

export default DigitalSecurity