import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div id='impressum-container'>
            <div className="container">
                <h1 style={{ marginBottom: "100px" }}>Privacy & Policy</h1>
                <div className='impressum-item'>
                    <h2 className="text-blue">Admirise Privacy Policy</h2>
                    <p className='mb-1'>Admirise is committed to protecting the privacy and confidentiality of users' personal and business information. This Privacy Policy outlines how Admirise collects, uses, discloses, and protects users' data and their rights concerning that personal information.</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Collection of Information</h2>
                    <p className='mb-1'>Admirise collects information about users of our site. This information provides us to identify which part of our website is more valuable to the users and how we can improve it continuously. As long as it is not a part of the registration, the user IDs remain anonymous. IP addresses are used to diagnose the problems with our servers and administer the website.</p>
                    <p className='mb-1'>The users may navigate to our website and get information, but they must register for any personalised service. When you fill out the Contact Form to get information about Admirise services or directly ask for a quote, the form requires a name, email, telephone, etc.; this contact information is necessary to learn more about visitors and the service they want. We may use users' information to contact users, marketing, or other information that may interest users. Users may opt to receive any of these by unsubscribing. </p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Disclosure of Personal Information</h2>
                    <p className='mb-1'>Admirise may disclose users' personal information to third-party service providers who assist us in providing our services to the users. Additionally, we may disclose personal data complying with legal obligations, protecting the rights of Admirise, and preventing possible wrongdoings in connection with our service.</p>
                    <p className='mb-1'>We may contain links to third-party websites to provide convenience to users, not as an endorsement by Admirise. We do not guarantee the accuracy, truthfulness, and reliability of any content posted by other users. We don't endorse the users' opinions, either; these will be at the users' risk.</p>
                    <p className='mb-1'>On www.admirise.com, we use cookies that enable users' Web browser places on their computers' hard drives to identify the users. We do not use cookies to collect any personal information and sell or make it available to third parties. The users may disable the cookies on their browsers or delete them with the help of their browsers' settings. But this will prevent us from recognising the users as the previous visitors of Admirise. </p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Feedback</h2>
                    <p className='mb-1'>We encourage the visitors to submit feedback. Any submitted feedback becomes the property of  https://www.admirise.com and may be used for marketing purposes or to contact visitors.</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Your Rights</h2>
                    <p className='mb-1'>Users have the right to access, correct, and delete their personal information and withdraw their consent to use their personal information at any time.</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Protection of Personal Information</h2>
                    <p className='mb-1'>Admirise takes reasonable and necessary precautions to protect the users' personal information from unauthorised disclosures, accesses, and misuse. When users share their sensitive information via the website, all information is protected online and offline.</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Privacy Policy Updates</h2>
                    <p className='mb-1'>Admirise reserves the right to update this Privacy Policy occasionally, and all changes will be posted here. The users' continued use of https://www.admirise.com services after any updates to the Privacy Policy signifies acceptance of the changes.</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Contact Us</h2>
                    <p className='mb-1'>If the users have any questions or concerns about this privacy policy statement, they can contact us anytime by emailing info@admirise.com or calling.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;