import React from 'react'
import { Link } from 'react-router-dom'

const CareerCard = () => {
    return (
        <>
            <div className='career-card'>
                <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='w-75 career-card-width'>
                        <h2>Product Designer</h2>
                        <p className='career-card-text mt-3'>We are seeking a talented and experienced Product Designer to join our dynamic and growing team.</p>
                        <div className='d-flex mt-4'>
                            <div className="badge-blue">Hybrid</div>
                            <div className="badge-blue ms-3">Full time</div>
                        </div>
                    </div>
                    <div className='mt-xl-0 mt-5 career-card-view-job'>
                        <Link to='/career-detail'>
                            <button className="btn btn-outline-blue py-3 px-5 buttonfx curtaindown">
                                View Job <i className="fa-solid fa-arrow-right ms-2"></i>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='career-card'>
                <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='w-75 career-card-width'>
                        <h2>Engineering Manager</h2>
                        <p className='career-card-text mt-3'>We are seeking a highly motivated and experienced Engineering Manager to lead our engineering team and drive the development of innovative products and solutions.</p>
                        <div className='d-flex mt-4'>
                            <div className="badge-blue">Remote</div>
                            <div className="badge-blue ms-3">Full time</div>
                        </div>
                    </div>
                    <div className='mt-xl-0 mt-5 career-card-view-job'>
                        <button className="btn btn-outline-blue py-3 px-5 buttonfx curtaindown">
                            View Job <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className='career-card'>
                <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <div className='w-75 career-card-width'>
                        <h2>Customer Success Manager</h2>
                        <p className='career-card-text mt-3'>We are seeking a dynamic and experienced Customer Success Manager to join our team. </p>
                        <div className='d-flex mt-4'>
                            <div className="badge-blue">In-house</div>
                            <div className="badge-blue ms-3">Part-time (%75)</div>
                        </div>
                    </div>
                    <div className='mt-xl-0 mt-5 career-card-view-job'>
                        <button className="btn btn-outline-blue py-3 px-5 buttonfx curtaindown">
                            View Job <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerCard;