import React from 'react'
import CareerCard from './CareerCard'

const CareerPositions = () => {
    return (
        <div>
            <h2 className='text-blue'>Open Positions</h2>
            <CareerCard />
        </div>
    )
}

export default CareerPositions;