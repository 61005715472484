import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../../assets/img/line-black.png'
import DriveImage from '../../assets/img/drive-img.png'

const DigitalMarketingDriveResults = () => {
    return (
        <div className='container digital-marketing-drive-container'>
            <h2 className='text-blue digital-marketing-subtitle'>Drive Results Through Cutting-Edge <br /> Digital Marketing Strategies</h2>
            <div className="row align-items-center mt-xl-5 mt-3">
                <div className="col-xl-5">
                    <img src={Line} alt="line" width='200px' height='2px' />
                    <p className='mt-xl-5 mt-3' style={{ fontSize: '24px', fontWeight: '500' }}>Every business is unique, so we develop the most suitable and exceptional digital marketing approach and strategy for your business.</p>
                    <div className='contact-form-submit-btn'>
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-3">
                                Free Analysis <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-7 mt-xl-0 mt-5">
                    <img src={DriveImage} alt="image" width='100%' />
                </div>
            </div>
        </div>
    )
}

export default DigitalMarketingDriveResults;