import React from 'react'
import Img from '../../assets/img/row-card-1.png'
import ServicesBg from '../../assets/img/services-bg.png'
import ServicesBg2 from '../../assets/img/website-2.png'
import ServicesBg3 from '../../assets/img/website-3.png'

const WebsiteRowCard = () => {
    return (
        <div className='container' style={{ padding: '125px 0' }}>
            <div className="row">
                <div className="col-xl-7">
                    <img src={Img} alt="" width='100%' />
                </div>
                <div className="col-xl-5 position-relative">
                    <img src={ServicesBg} alt="" width='100%' className='row-card-bg' />
                    <div className='text-center services-card-website'>
                        Save Time, Labour, and Money with Product & Category Automation and CMS Integration
                    </div>
                </div>
            </div>
            <div className="row mt-4 website-row-card-column">
                <div className="col-xl-6 position-relative">
                    <img src={ServicesBg} alt="" width='100%' className='row-card-bg2' />
                    <div className='text-center services-card-website'>
                        A Website that Provides a Secure and Easy Shopping Experience
                    </div>
                </div>
                <div className="col-xl-6">
                    <img src={ServicesBg2} alt="" width='100%' />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-xl-5">
                    <img src={ServicesBg3} alt="" width='100%' />
                </div>
                <div className="col-xl-7 position-relative">
                    <img src={ServicesBg} alt="" width='100%' className='row-card-bg3' />
                    <div className='text-center services-card-website'>
                        Your Brand is Everywhere: Online Marketplace Integration, Data Feed Generation, Seamless Migration Process
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebsiteRowCard;