import React from 'react'
import ComingSoon from '../../assets/img/coming-soon.jpg'

const ProductsInner = () => {
    return (
        <div id='products-inner-container'>
            <div className="w-100 text-center">
                <img src={ComingSoon} alt="" width='50%' />
            </div>
        </div>
    )
}

export default ProductsInner