import React from 'react'
import { Link } from 'react-router-dom'
import Img from "../../assets/img/digital-sec-1.png"
import Img2 from "../../assets/img/digital-sec-2.png"
import Plus from "../../assets/img/plus-blue.png"
import Line from "../../assets/img/line-black.png"

const DigitalSecurityRow = () => {
    return (
        <div className='container'>
            <div className="row align-items-center">
                <div className="col-xl-7">
                    <img src={Img} alt="" width="100%" />
                </div>
                <div className="col-xl-5 mt-xl-0 mt-3">
                    <h2 className="text-blue">
                        <div className="d-flex align-items-start">
                            <img src={Plus} alt="" />
                            <div className='ps-3'>
                                Secure Your Online Presence
                            </div>
                        </div>
                    </h2>
                    <p style={{ fontSize: "22px" }}>We use the latest security technologies and techniques to detect and prevent threats and provide ongoing monitoring and maintenance to keep your network safe.</p>
                    <div className='contact-form-submit-btn'>
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mt-5 digital-sec-row-mobile">
                <div className="col-xl-5">
                    <h2 className='mt-xl-0 mt-3' style={{ fontSize: "42px" }}>
                        Protect Your Network by Utilizing the Latest Techniques & Technologies
                    </h2>
                    <img className='mobile-d-none' src={Line} alt="" width="200px" height="1px" />
                    <div className='mobile-d-none'>
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-7">
                    <img src={Img2} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default DigitalSecurityRow