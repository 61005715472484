import React from 'react'
import Banner from "../assets/img/blog-detail.png"
import LeftArrow from "../assets/img/left-btn.png"
import Blog2 from "../assets/img/blog-detail-2.png"
import Blog3 from "../assets/img/blog-detail-3.png"
import Blog4 from "../assets/img/blog-detail-4.png"

const MachineLearningDetail = () => {
    return (
        <div id='machine-learning-container'>
            <img src={Banner} alt="" width="100%" className='machine-learning-banner' />
            <div className="container my-5">
                <div className='d-flex align-items-baseline'>
                    <img src={LeftArrow} alt="" />
                    <h1 className='ms-4'>Machine Learning in E-commerce</h1>
                </div>
                <div className='mt-5'>
                    <h2 className="text-blue">Title #1</h2>
                    <p style={{ fontSize: "20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere rhoncus lorem vel semper. Vivamus hendrerit metus a fringilla pharetra. Nunc non lacus sodales, dictum nulla quis, iaculis augue. Suspendisse sodales ligula at vehicula volutpat. Vivamus euismod lacus nibh, eu egestas nisl congue lacinia. Sed suscipit non libero id vestibulum. Nullam et commodo felis. Suspendisse ut eros non mauris pulvinar posuere in ut urna. Fusce dapibus luctus dui, et elementum elit faucibus non. Nulla sed ipsum metus. Proin eu mattis tortor, id convallis orci.</p>
                    <div className='row mt-4'>
                        <div className="col-xl-6">
                            <img src={Blog2} alt="" width="100%" />
                        </div>
                        <div className="col-xl-6 mt-xl-0 mt-4">
                            <img src={Blog3} alt="" width="100%" />
                        </div>
                    </div>
                    <p className='mt-4' style={{ fontSize: "20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere rhoncus lorem vel semper. Vivamus hendrerit metus a fringilla pharetra. Nunc non lacus sodales, dictum nulla quis, iaculis augue. Suspendisse sodales ligula at vehicula volutpat. Vivamus euismod lacus nibh, eu egestas nisl congue lacinia. </p>
                </div>
                <div className='mt-5'>
                    <h2 className="text-blue">Title #2</h2>
                    <div className='row mt-4'>
                        <div className="col-xl-6">
                            <p style={{ fontSize: "20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere rhoncus lorem vel semper. Vivamus hendrerit metus a fringilla pharetra. Nunc non lacus sodales, dictum nulla quis, iaculis augue. Suspendisse sodales ligula at vehicula volutpat. Vivamus euismod lacus nibh, eu egestas nisl congue lacinia. Sed suscipit non libero id vestibulum. Nullam et commodo felis. Suspendisse ut eros non mauris pulvinar posuere in ut urna. Fusce dapibus luctus dui, et elementum elit faucibus non. Nulla sed ipsum metus. Proin eu mattis tortor, id convallis orci.</p>
                        </div>
                        <div className="col-xl-6">
                            <img src={Blog4} alt="" width="100%" />
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <h2 className="text-blue">Conclusion</h2>
                    <div className='mt-4'>
                        <p style={{ fontSize: "20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam posuere rhoncus lorem vel semper. Vivamus hendrerit metus a fringilla pharetra. Nunc non lacus sodales, dictum nulla quis, iaculis augue.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineLearningDetail;