import React from 'react'
import Slider from "react-slick"

const CloudManagementServices = () => {
    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container digital-marketing-services-container'>
            <h2>Our Services</h2>
            <Slider {...settings}>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Cloud Migration</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide a seamless migration process to move your data and systems to the cloud, such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Systems.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>On-Premise System Management</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide ongoing maintenance and management of your on-premise systems to ensure they are always up-to-date, secure, and running smoothly.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Cloud Security</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our team ensures your cloud systems and your data are protected and secure, by implementing the latest security technologies and best practices.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Backup and Disaster Recovery</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide a comprehensive backup and disaster recovery service to ensure that your data is always protected and can be quickly restored in the event of and incident.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Scalability</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our cloud and on-premise system management service are scalable to meet the needs of business of any size, whether you're a small startup or a large enterprise.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Monitoring and Troubleshooting</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Admirise provides ongoing monitoring and troubleshooting to ensure your sytems are always running smoothly and that we resolve any issues quickly.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default CloudManagementServices;