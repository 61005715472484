import React from 'react'
import Slider from "react-slick"

const DataIntelligenceSlider = () => {
    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container digital-marketing-services-container'>
            <h2>Our Services</h2>
            <Slider {...settings}>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Automated Data Processing</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We use advanced data processing techniques and tools to automate data processing and analysis, saving you time and resources.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Real-Time Data Monitoring</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide real-time monitoring of your data to ensure you always have access to the most up-to-date information.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Machine Learning</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our team of experts uses machine learning algorithms to help you uncover hidden insights in your data and make more accurate predictions.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Integration with Other Systems</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our data intelligence service can integra e-commerce websites with other systems, such as CRM, ERP, and analytic tools, to provide a seamless and comprehensive approach to your business.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Scalability</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our work is scalable to meet the needs of business of any size, whether you're a small startup or a large enterprise.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Customizable Reporting</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide reporting tailored to your specific needs, helping you make sense of your data and make better business decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default DataIntelligenceSlider;