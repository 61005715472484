import React from 'react'
import Img from "../../assets/img/cyber-image.png"
import Plus from "../../assets/img/plus.png"

const DigitalSecurityRisk = () => {
    return (
        <div className='row gx-0'>
            <div className="col-xl-5" style={{ borderTop: "1px solid #000", borderBottom: "1px solid #000" }}>
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <h2 style={{ fontSize: "42px" }}>Digital Risk Protection</h2>
                    <p style={{ fontSize: "22px" }}>In today's ever-changing digital <br /> landscape, our comprehensive and <br /> value-added service:</p>
                </div>
            </div>
            <div className="col-xl-7 position-relative">
                <img src={Img} alt="" width="100%" className='risk-absolute-img' />
                <div className='digital-sec-risk-absolute-text'>
                    <div className='d-flex align-items-start risk-mobile-text'>
                        <img src={Plus} alt="" />
                        <p className='ms-xl-5 ms-3' style={{ fontSize: "28px" }}>Stay ahead of the curve and stay protected with our Digital Risk Protection services.</p>
                    </div>
                    <div className='d-flex align-items-start risk-mobile-text mt-4'>
                        <img src={Plus} alt="" />
                        <p className='ms-xl-5 ms-3' style={{ fontSize: "28px" }}>Don't let your enterprise digital assets become a target of these threats.</p>
                    </div>
                    <div className='d-flex align-items-start risk-mobile-text mt-4'>
                        <img src={Plus} alt="" />
                        <p className='ms-xl-5 ms-3' style={{ fontSize: "28px" }}>Protect your business's digital assets and online reputation.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalSecurityRisk;