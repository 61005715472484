import React from 'react'
import CaseStCard from '../components/CaseStudies/CaseStCard'

const CaseStudiesInner = () => {
    return (
        <div id='case-studies-inner'>
            <div className="container case-studies-container">
                <h1 className='case-studies-title'>Case Studies</h1>
                <CaseStCard />
            </div>
        </div>
    )
}

export default CaseStudiesInner;