import React from 'react'
import Case1 from '../../assets/img/case-img.png'
import Case2 from '../../assets/img/case-img-2.png'
import Case3 from '../../assets/img/case-3.png'
import Case4 from '../../assets/img/case-4.png'
import Case5 from '../../assets/img/case-5.png'

const CaseStCard = () => {
    return (
        <div className='row justify-content-between mt-5 case-studies-card-container'>
            <div className="col-xl-5">
                <div className="case-card position-relative">
                    <div className="case-card-img-container">
                        <img src={Case1} alt="case-img" width="100%" />
                    </div>
                    <div className="case-card-hover d-flex">
                        <div className="case-card-hover-badge">
                            SEO
                        </div>
                        <div className="case-card-hover-badge ms-3">
                            SEM
                        </div>
                    </div>
                    <div className="case-card-body p-4">
                        <h3>Chef's Deal</h3>
                        <p className="mb-0">
                            Chef's Deal is a company that sells its products both in-store, over the
                            phone,
                            and on its
                            website.
                        </p>
                        <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                            Read More <i className="fa-solid fa-arrow-right ms-2" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-5">
                <div className="case-card position-relative">
                    <div className="case-card-img-container">
                        <img src={Case2} alt="case-img" width="100%" />
                    </div>
                    <div className="case-card-hover d-flex">
                        <div className="case-card-hover-badge">
                            SEM
                        </div>
                        <div className="case-card-hover-badge ms-3">
                            SEO & Local SEO
                        </div>
                    </div>
                    <div className="case-card-body p-4">
                        <h3>CabinetLand</h3>
                        <p className="mb-0">
                            Cabinetland is a local company that specializes in kitchen and bathroom remodeling.
                        </p>
                        <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                            Read More <i className="fa-solid fa-arrow-right ms-2" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-5">
                <div className="case-card tampap-case-card position-relative">
                    <div className="case-card-img-container">
                        <img src={Case3} alt="case-img" width="100%" />
                    </div>
                    <div className="case-card-hover d-flex">
                        <div className="case-card-hover-badge">
                            Web Development
                        </div>
                        <div className="case-card-hover-badge ms-3">
                            SEO & SEM
                        </div>
                    </div>
                    <div className="case-card-body p-4">
                        <h3>Tampap</h3>
                        <p className="mb-0">
                            At the time of their application, Tampap operated exclusively within marketplaces for product sales.
                        </p>
                        <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                            Read More <i className="fa-solid fa-arrow-right ms-2" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-5">
                <div className="case-card position-relative">
                    <div className="case-card-img-container">
                        <img src={Case4} alt="case-img" width="100%" />
                    </div>
                    <div className="case-card-hover d-flex">
                        <div className="case-card-hover-badge">
                            Web Development
                        </div>
                        <div className="case-card-hover-badge ms-3">
                            SEO & SEM
                        </div>
                    </div>
                    <div className="case-card-body p-4">
                        <h3>Trimmob</h3>
                        <p className="mb-0">
                            Trimmob is a company that seeks to sell products both online and in their physical store.
                        </p>
                        <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                            Read More <i className="fa-solid fa-arrow-right ms-2" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-xl-5">
                <div className="case-card antlara-case-card position-relative">
                    <div className="case-card-img-container">
                        <img src={Case5} alt="case-img" width="100%" />
                    </div>
                    <div className="case-card-hover d-flex">
                        <div className="case-card-hover-badge">
                            Semantic SEO
                        </div>
                    </div>
                    <div className="case-card-body p-4">
                        <h3>Antlara Dental</h3>
                        <p className="mb-0">
                            Antlara Dental is a modern dental clinic in Turkey that provides high-quality treatments to both domestic and international patients.
                        </p>
                        <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                            Read More <i className="fa-solid fa-arrow-right ms-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseStCard