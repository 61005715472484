import React from 'react'
import Person from '../../assets/img/person.png'

const ContactPerson = () => {
    return (
        <div className='row contact-person-container'>
            <div className="col-xl-2 col-4">
                <img src={Person} alt="person" width='100%' />
            </div>
            <div className="col-xl-4 col-8">
                <h3 className='mb-xl-3 mb-1'>Elliot <br /> Montgomery</h3>
                <h4 className='mb-xl-3 mb-1' style={{ fontWeight: '400' }}>Head of Business Development</h4>
                <h4 style={{ fontWeight: '400' }}>business@admirise.de</h4>
            </div>
            <div className="col-xl-6 mt-xl-0 mt-4">
                <p className='contact-person-text'>
                    At our ecommerce agency, we prioritize prompt communication and are committed to providing you with the right point of contact. Expect to hear from us soon!
                    <br /><br />
                    Let's kickstart our collaboration by delving into your specific obstacles during our first online discussion. Our goal is to identify how we can provide tailored assistance and drive your success. We are excited to connect with you and begin our journey together!
                </p>
            </div>
        </div>
    )
}

export default ContactPerson;