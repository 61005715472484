import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../../assets/img/e-commerce-card.png'
import Card2 from '../../assets/img/e-commerce-card-2.png'

const ECommerceInfoCard = () => {
    return (
        <div className='container my-5'>
            <div className="row justify-content-between">
                <div className="col-xl-5">
                    <img src={Card} alt="image" width="100%" />
                    <h2 className='text-blue my-4'>Secure and Confident Data Management</h2>
                    <p className='mb-3' style={{ fontSize: '22px' }}>We set and conduct a flexible and reachable communication structure between you and our teams during the service processes.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-0 mt-5">
                    <img src={Card2} alt="image" width="100%" />
                    <h2 className='text-blue my-4'>Transparent and Scalable Service</h2>
                    <p className='mb-3' style={{ fontSize: '22px' }}>Our e-commerce management service is scalable to meet the needs of businesses of any size, whether you're a small startup or a large enterprise.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ECommerceInfoCard