import React from 'react'

const CareerBanner = () => {
    return (
        <div className='row align-items-center career-banner-container'>
            <div className="col-xl-4">
                <h1 className='career-banner-title'>Welcome to Our Career Page!</h1>
            </div>
            <div className="col-xl-8">
                <p className='career-banner-text mt-xl-0 mt-3'>We are a dynamic and growing company that is dedicated to providing innovative solutions and excellent service to our customers. We believe that our success is driven by the talent and commitment of our team members, and we are always looking for talented individuals to join our team.</p>
            </div>
        </div>
    )
}

export default CareerBanner