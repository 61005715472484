import React from 'react'
import { Link } from 'react-router-dom'
import PlusBlue from "../assets/img/plus-blue.png"
import ServicesHover from "../assets/img/services-hover-3.png"
import CreativeBanner from "../assets/img/creative-banner.png"
import Line from "../assets/img/long-line.png"
import Bg from "../assets/img/small-card-bg.png"
import Bg2 from "../assets/img/small-card-bg-2.png"
import CreativeContentAccordion from '../components/Services/CreativeContentAccordion'
import CreativeContentSlider from '../components/Services/CreativeContentSlider'

const CreativeContentDesign = () => {
    return (
        <section id="creative-content-design-container">
            <div class="container">
                <div class="row align-items-end justify-content-between">
                    <div class="col-xl-4">
                        <h1>
                            <span class="creative-banner-span">Creative</span>
                            Content Design
                        </h1>
                    </div>
                    <div class="col-xl-7">
                        <p class="creative-content-banner-text">
                            Admirise specialises in enabling online business
                            success through the effective communication of your
                            message with visually compelling and emotionally
                            engaging images, animations, and videos.
                        </p>
                        <Link to='/contact'>
                            <button
                                class="btn btn-outline-blue py-3 px-3 buttonfx curtaindown"
                            >
                                Contact Us
                                <i class="fa-solid fa-arrow-right ms-2"></i>
                            </button>
                        </Link>
                    </div>
                </div>
                <div class="row justify-content-between" style={{ marginTop: '150px' }}>
                    <div class="col-xl-4">
                        <div class="d-flex align-items-start">
                            <img src={PlusBlue} alt="plus" />
                            <h2 class="mb-0 text-blue ms-4">
                                Make an Impact with Visually Stunning and
                                Engaging Content.
                            </h2>
                        </div>
                        <p class="mb-0 mt-3" style={{ fontSize: "20px" }}>
                            By choosing our creative content design service, you
                            will be able to present visually stunning and
                            engaging content that will capture the attention of
                            your target audience and improve your business
                            performance.
                        </p>
                        <button
                            class="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-5"
                        >
                            Get Quote
                            <i class="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </div>
                    <div class="col-xl-7 mt-xl-0 mt-5">
                        <img
                            src={ServicesHover}
                            alt="image"
                            width="100%"
                        />
                    </div>
                </div>
            </div>

            <CreativeContentSlider />

            <div class="mt-5 position-relative">
                <img
                    src={CreativeBanner}
                    alt="creative-banner"
                    width="100%"
                    className='creative-banner-bg-image'
                />
                <div class="creative-banner-text-container">
                    <h3 class="mb-4">
                        UX/UI <br />
                        Design
                    </h3>
                    <img
                        src={Line}
                        alt="long-line"
                    />
                    <p class="mt-4 mb-0">
                        The ultimate goal of UX design is to create products and
                        services that are not only functional but also enjoyable
                        to use, making the user's experience as smooth and
                        satisfying as possible.
                    </p>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row">
                    <div class="col-xl-6">
                        <img
                            src={Bg}
                            alt="image"
                            width="100%"
                        />
                        <div class="w-75 my-4 creative-card-width">
                            <h2 class="mb-0 hover-underline">
                                Express Your Message Effectively and Captivate
                                the Attention of Your Target Audience
                            </h2>
                        </div>
                        <div className="contact-form-submit-btn">
                            <Link to='/contact'>
                                <button
                                    class="btn btn-outline-blue py-3 px-3 buttonfx curtaindown"
                                >
                                    Contact Us
                                    <i class="fa-solid fa-arrow-right ms-2"></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div class="col-xl-6 mt-xl-0 mt-5">
                        <img
                            src={Bg2}
                            alt="image"
                            width="100%"
                        />
                        <div class="w-75 my-4 creative-card-width">
                            <h2 class="mb-0 hover-underline">
                                Elevate Your Online Business with Our Creative
                                Content Design Services
                            </h2>
                        </div>
                        <div className="contact-form-submit-btn">
                            <Link to='/contact'>
                                <button
                                    class="btn btn-outline-blue py-3 px-3 buttonfx curtaindown"
                                >
                                    Free Analysis
                                    <i class="fa-solid fa-arrow-right ms-2"></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CreativeContentAccordion />
        </section>
    )
}

export default CreativeContentDesign