import React from 'react'
import CloudImg1 from "../../assets/img/cloud-img-1.png"
import CloudImg2 from "../../assets/img/cloud-img-2.png"
import Earth from "../../assets/img/cloud-earth.png"
import Phone from "../../assets/img/cloud-phone.png"
import Branch from "../../assets/img/cloud-branch.png"
import Globe from "../../assets/img/cloud-globe.png"

const CloudManagementOptimize = () => {
    return (
        <div className='container'>
            <div className="row align-items-end">
                <div className="col-xl-7">
                    <img src={CloudImg1} alt="" width="100%" />
                </div>
                <div className="col-xl-5 mt-xl-0 mt-3">
                    <h3 className='ps-xl-5 cloud-mng-optimize-text'>Optimize Your IT Infrastructure and Improve Your Business Performance with Our Reliable and Efficient Cloud System Management Service</h3>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-xl-5">
                    <div className="cloud-bg-card">
                        <img src={CloudImg2} alt="" width="100%" />
                        <p className='py-xl-5 px-xl-4 py-3 px-3 text-white' style={{ fontSize: "26px" }}>Optimize Your IT Infrastructure and Improve Your Business Performance with Our Reliable and Efficient Cloud System Management Service</p>
                    </div>
                </div>
                <div className="col-xl-7 mt-xl-0 mt-5">
                    <div className="row">
                        <div className="col-xl-6 col-6">
                            <div className="cloud-icon-card">
                                <img src={Earth} alt="" className='mb-4' />
                                <h3>Infrastructure</h3>
                            </div>
                        </div>
                        <div className="col-xl-6 col-6">
                            <div className="cloud-icon-card">
                                <img src={Phone} alt="" className='mb-4' />
                                <h3>Application</h3>
                            </div>
                        </div>
                        <div className="col-xl-6 col-6 mt-4">
                            <div className="cloud-icon-card">
                                <img src={Branch} alt="" className='mb-4' />
                                <h3>Data Storage</h3>
                            </div>
                        </div>
                        <div className="col-xl-6 col-6 mt-4">
                            <div className="cloud-icon-card">
                                <img src={Globe} alt="" className='mb-4' />
                                <h3 className='text-center'>Network <br /> Administration</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CloudManagementOptimize;