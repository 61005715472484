import React from 'react'
import Line from '../../assets/img/line.png'

const AdvantagesAdmAccordion = () => {
    return (
        <div>
            <div className="accordion mt-5" id="accordionExample">
                <div className="accordion-item accordion-item-1">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className>
                                <div className="accordion-mobile-text-container">
                                    <div className="accordion-text mb-2">
                                        <span className="me-3">01</span>
                                        <img src={Line} alt="line" className="me-xl-0 me-3" />
                                    </div>
                                    <div className="accordion-text-big">
                                        <div>Experience</div>
                                    </div>
                                </div>
                                <div className="accordion-mobile-text mt-2">
                                    <p className="mb-0">
                                        You will benefit from years of Admirise's experience in the digital industry.
                                    </p>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p className="mb-0">
                                You will benefit from years of Admirise's experience in the digital industry.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-item-2 mt-1">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className>
                                <div className="accordion-mobile-text-container">
                                    <div className="accordion-text mb-2">
                                        <span className="me-3">02</span>
                                        <img src={Line} alt="line" className="me-xl-0 me-3" />
                                    </div>
                                    <div className="accordion-text-big">
                                        <div>Approach</div>
                                    </div>
                                </div>
                                <div className="accordion-mobile-text mt-2">
                                    <p className="mb-0">
                                        With our transparent and agile digital approach, you will have digital excellence
                                        and
                                        achieve your goals efficiently.
                                    </p>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p className="mb-0">
                                With our transparent and agile digital approach, you will have digital excellence and
                                achieve your goals efficiently.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-item-3 mt-1">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className>
                                <div className="accordion-mobile-text-container">
                                    <div className="accordion-text mb-2">
                                        <span className="me-3">03</span>
                                        <img src={Line} alt="line" className="me-xl-0 me-3" />
                                    </div>
                                    <div className="accordion-text-big">
                                        <div>User-Centric</div>
                                    </div>
                                </div>
                                <div className="accordion-mobile-text mt-2">
                                    <p className="mb-0">
                                        Customer and data-centered strategies will be implemented for your business.
                                    </p>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p className="mb-0">
                                Customer and data-centered strategies will be implemented for your business.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-item-4 mt-1">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <div className>
                                <div className="accordion-mobile-text-container">
                                    <div className="accordion-text mb-2">
                                        <span className="me-3">04</span>
                                        <img src={Line} alt="line" className="me-xl-0 me-3" />
                                    </div>
                                    <div className="accordion-text-big">
                                        <div>360° E-commerce</div>
                                    </div>
                                </div>
                                <div className="accordion-mobile-text mt-2">
                                    <p className="mb-0">
                                        You will get every service related to your online business from a single source.
                                    </p>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p className="mb-0">
                                You will get every service related to your online business from a single source.
                            </p>
                            <ul className="accordion-list">
                                <li>E-commerce Management</li>
                                <li>Web Development</li>
                                <li>Creative Design</li>
                                <li>Digital Marketing and Consulting</li>
                                <li>Data Intelligence</li>
                                <li>Digital Security</li>
                                <li>Cloud Management</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item accordion-item-5 mt-1">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <div className>
                                <div className="accordion-mobile-text-container">
                                    <div className="accordion-text mb-2">
                                        <span className="me-3">05</span>
                                        <img src={Line} alt="line" className="me-xl-0 me-3" />
                                    </div>
                                    <div className="accordion-text-big">
                                        <div>Technology</div>
                                    </div>
                                </div>
                                <div className="accordion-mobile-text mt-2">
                                    <p className="mb-0">
                                        You will have the ability to utilize diverse technologies.
                                    </p>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <p className="mb-0">
                                You will have the ability to utilize diverse technologies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvantagesAdmAccordion