import React from 'react'

const MarqueeText = () => {
    return (
        <div className='d-flex marquee-text'>
            <marquee behavior="scroll" direction="right" scrollamount="20"><span className='me-5'>Admirable Rise With Us</span> <span className='me-5'>Admirable Rise With Us</span> <span className="me-5">Admirable Rise With Us</span> Admirable Rise With Us</marquee>
        </div>
    )
}

export default MarqueeText