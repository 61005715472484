import React from 'react'
import { Link } from 'react-router-dom'

const DigitalMarketingBanner = () => {
    return (
        <div className="container digital-marketing-banner">
            <div className='row align-items-center'>
                <div className="col-xl-4">
                    <h1 className='career-banner-title'>Digital <span className='marketing-span'>Marketing</span></h1>
                </div>
                <div className="col-xl-8 mt-xl-0 mt-4">
                    <p className='career-banner-text'>A resilient digital presence in today's fast-paced business landscape is crucial. That's why we offer comprehensive digital marketing services for all sizes of businesses to help reach their target audience, drive conversions, and increase revenues.</p>
                    <div className='contact-form-submit-btn'>
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalMarketingBanner