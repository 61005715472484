import React from 'react'
import { Link } from 'react-router-dom'
import Img from '../../assets/img/col-card-1.png'
import Img2 from '../../assets/img/website-4.png'

const WebsiteColCard = () => {
    return (
        <div className='container'>
            <div className="row website-col-card-row">
                <div className="col-xl-9 mt-xl-0 mt-3">
                    <h2 className='text-blue' style={{ fontSize: '36px' }}>Special Development Helps You Stay Ahead of The Competition</h2>
                    <p className='mt-4' style={{ fontSize: '22px' }}>Our team employs the latest technologies and best practices in designing a visually appealing, user-friendly website optimized for the highest conversions.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-3">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-3">
                    <img src={Img} alt="" width='100%' />
                </div>
            </div>
            <div className="row website-col-card-row mt-5">
                <div className="col-xl-9 mt-xl-0 mt-3">
                    <h2 className='text-blue' style={{ fontSize: '36px' }}>Protected Data with Regularly Backups and Updated and Secured Website with Maintenance and Support</h2>
                    <p className='mt-4' style={{ fontSize: '22px' }}>Partner with us for a worry-free website experience - our developers ensure your website meets industry standards and regulations, while our team keeps your website up-to-date, secure, and optimized, with regular backups to safeguard your data.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-3">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-3">
                    <img src={Img2} alt="" width='100%' />
                </div>
            </div>
        </div>
    )
}

export default WebsiteColCard;