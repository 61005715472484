import React from 'react'
import RightArrow from '../../assets/img/right-arrow.png'
import Plus from '../../assets/img/plus-black.png'

const DigitalSecuritySlide = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-12" style={{ overflow: 'hidden' }}>
                    <div className="d-flex quality-values-card">
                        <div className="mt-1 quality-plus">
                            <img src={Plus} alt="plus" />
                        </div>
                        <div className="ms-4 quality-values-card-title-container quality-title">
                            <p className="mb-0 quality-values-card-title">
                                Protect Your Digital Business from Cyber Threats
                            </p>
                        </div>
                        <div className="ms-4 pe-5 quality-text">
                            <p className="mb-0 quality-values-card-text">
                                In the event of a cyber security incident, our cyber security incident response service ensures that your business is resilient against cyber attacks.
                            </p>
                        </div>
                        <div className="quality-values-card-arrow">
                            <img src={RightArrow} alt="right-arrow" width="20px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalSecuritySlide