import React from 'react'
import File from '../../assets/img/admirise-images/file-upload.png'

const CareerDetail = () => {
    return (
        <>
            <div id='career-detail-container'>
                <div className="container">
                    <div className='row align-items-center career-banner-container'>
                        <div className="col-xl-4">
                            <h1 className='career-banner-title'>Product Designer</h1>
                        </div>
                        <div className="col-xl-8 text-end d-mobile-none">
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                                Apply <i className="fa-solid fa-arrow-right ms-2"></i>
                            </button>
                            <div className='d-flex justify-content-end mt-5'>
                                <div className="badge-blue">Remote</div>
                                <div className="badge-blue ms-3">Full time</div>
                            </div>
                        </div>
                    </div>
                    <div className='impressum-item'>
                        <h2 className="text-blue">Key Responsibilities</h2>
                        <ul>
                            <li>Work closely with product managers, engineers, and stakeholders to understand business goals, user needs, and technical requirements.</li>
                            <li>Conduct user research and usability testing to identify user needs, pain points, and preferences.</li>
                            <li>Create design concepts, wireframes, and prototypes that effectively communicate product functionality and user experience.</li>
                            <li>Design user interfaces and interactions that are intuitive, engaging, and visually appealing.</li>
                            <li>Collaborate with developers to ensure that designs are implemented accurately and efficiently.</li>
                            <li>Continuously iterate and refine designs based on user feedback, data analysis, and industry trends.</li>
                            <li>Stay up-to-date with the latest design trends, tools, and technologies, and share knowledge with the team.</li>
                        </ul>
                    </div>
                    <div className='impressum-item'>
                        <h2 className="text-blue">Qualifications</h2>
                        <ul>
                            <li>Bachelor's degree in Graphic Design, Interaction Design, or a related field.</li>
                            <li>Minimum of [insert number of years] years of experience in product design, UX design, or a related field.</li>
                            <li>Strong portfolio demonstrating expertise in product design, user-centered design, and interaction design.</li>
                            <li>Proficiency in design tools such as Sketch, Figma, or Adobe Creative Suite.</li>
                            <li>Experience conducting user research, usability testing, and data analysis.</li>
                            <li>Excellent communication, collaboration, and problem-solving skills.</li>
                            <li>Ability to manage multiple projects and priorities in a fast-paced environment.</li>
                            <li>Passion for designing products that solve real-world problems and improve people's lives.</li>
                        </ul>
                    </div>
                    <div className='impressum-item'>
                        <p className='mb-1' style={{ fontSize: '20px' }}>If you meet the above qualifications and are excited about the opportunity to join a dynamic and growing team, we encourage you to submit your application. We offer competitive compensation packages, comprehensive benefits, and opportunities for career advancement.</p>
                    </div>
                    <div className='contact-form-submit-btn'>
                        <button className="btn btn-outline-blue py-3 px-4 buttonfx curtaindown my-5" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                            Apply <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-body small">
                    <div className="row align-items-end px-xl-5">
                        <div className="col-xl-6 px-xl-5">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h2 style={{ fontSize: '48px' }}>Product Designer</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <p style={{ fontSize: '22px' }}>We are seeking a talented and experienced Product Designer to join our dynamic and growing team. As a Product Designer, you will be responsible for designing and developing innovative and user-centric products that meet our customers' needs and align with our business goals.</p>
                            <div className='d-flex mt-4'>
                                <div className="badge-blue" style={{ fontSize: '20px' }}>Remote</div>
                                <div className="badge-blue ms-3" style={{ fontSize: '20px' }}>Full time</div>
                            </div>
                            <div>
                                <button className="btn btn-outline-blue py-3 px-4 buttonfx curtaindown mt-5 w-100">
                                    Submit Application <i className="fa-solid fa-arrow-right ms-2"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-xl-6 px-xl-5">
                            <div className="row">
                                <div className="col-xl-6 mt-xl-0 mt-4">
                                    <input type="text" className='form-control' placeholder='First Name' />
                                </div>
                                <div className="col-xl-6 mt-xl-0 mt-4">
                                    <input type="text" className='form-control' placeholder='Last Name' />
                                </div>
                                <div className="col-xl-6 mt-4">
                                    <input type="text" className='form-control' placeholder='Phone' />
                                </div>
                                <div className="col-xl-6 mt-4">
                                    <input type="text" className='form-control' placeholder='Email' />
                                </div>
                                <div className="col-xl-12 mt-4">
                                    <label htmlFor="cv" className='d-flex justify-content-between'><div>CV</div> <div><img src={File} alt="" /></div></label>
                                    <input type="file" className='form-control' placeholder='CV' id='cv' />
                                </div>
                                <div className="col-xl-12 mt-4">
                                    <label htmlFor="additional" className='d-flex justify-content-between'><div>Additional files</div> <div><img src={File} alt="" /></div></label>
                                    <input type="file" className='form-control' placeholder='Additional' id='additional' />
                                </div>
                                <div className="col-xl-12 mt-4">
                                    <textarea rows="5" className='form-control' placeholder='Cover Letter'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerDetail;