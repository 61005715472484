import React, { useState, useEffect } from 'react'
import Adobe from '../../assets/img/admirise-images/adobe.png'
import Php from '../../assets/img/admirise-images/php.png'
import Aws from '../../assets/img/admirise-images/aws.png'
import Bi from '../../assets/img/admirise-images/power-bi.png'
import Woo from '../../assets/img/admirise-images/woo.png'
import Mysql from '../../assets/img/admirise-images/mysql.svg'
import Cloud from '../../assets/img/admirise-images/google-cloud.png'
import Hetzner from '../../assets/img/admirise-images/hetzner.png'
import Shopify from '../../assets/img/admirise-images/shopify.png'
import Python from '../../assets/img/admirise-images/python.png'
import Sql from '../../assets/img/admirise-images/sql.png'
import Magento from '../../assets/img/admirise-images/magento.png'
import Linux from '../../assets/img/admirise-images/linux.png'

const Customers = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);
    return (
        < >
            {matches && (
                <div className='container' style={{ paddingTop: '100px' }}>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className='customer-item'>
                            <img src={Adobe} alt="" width='150px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Php} alt="" width='100px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Aws} alt="" width='75px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Bi} alt="" width='100px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Woo} alt="" width='90px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Mysql} alt="" width='120px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Cloud} alt="" width='150px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Hetzner} alt="" width='150px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-wrap mt-5">
                        <div className='customer-item me-4'>
                            <img src={Shopify} alt="" width='110px' />
                        </div>
                        <div className='customer-item me-5'>
                            <img src={Python} alt="" width='110px' />
                        </div>
                        <div className='customer-item me-5'>
                            <img src={Sql} alt="" width='75px' />
                        </div>
                        <div className='customer-item me-5'>
                            <img src={Magento} alt="" width='130px' />
                        </div>
                        <div className='customer-item me-5'>
                            <img src={Linux} alt="" width='120px' />
                        </div>
                    </div>
                </div>
            )}
            {!matches && (
                <div className='container' style={{ paddingTop: '100px' }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='customer-item'>
                            <img src={Adobe} alt="" width='120px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Php} alt="" width='80px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Aws} alt="" width='60px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <div className='customer-item'>
                            <img src={Bi} alt="" width='80px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Woo} alt="" width='70px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Mysql} alt="" width='110px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <div className='customer-item'>
                            <img src={Cloud} alt="" width='120px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Hetzner} alt="" width='120px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Shopify} alt="" width='90px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-5">
                        <div className='customer-item'>
                            <img src={Python} alt="" width='110px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Sql} alt="" width='70px' />
                        </div>
                        <div className='customer-item'>
                            <img src={Magento} alt="" width='110px' />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className='customer-item'>
                            <img src={Linux} alt="" width='100px' />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Customers;