import React from 'react'
import RightArrow from '../../assets/img/right-arrow.png'
import Plus from '../../assets/img/plus-black.png'

const DigitalMarketingSliding = () => {
    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-xl-12" style={{ overflow: 'hidden' }}>
                    <div className="d-flex quality-values-card">
                        <div className="mt-1 quality-plus">
                            <img src={Plus} alt="plus" />
                        </div>
                        <div className="ms-4 quality-values-card-title-container quality-title">
                            <p className="mb-0 quality-values-card-title">
                                Innovative Solutions for Your Digital Success
                            </p>
                        </div>
                        <div className="ms-4 pe-5 quality-text">
                            <p className="mb-0 quality-values-card-text">
                                We analyse your current digital marketing efforts and implement our strategy in line with your goals.
                            </p>
                        </div>
                        <div className="quality-values-card-arrow">
                            <img src={RightArrow} alt="right-arrow" width="20px" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                    <div className="d-flex quality-values-card">
                        <div className="mt-1 quality-plus">
                            <img src={Plus} alt="plus" />
                        </div>
                        <div className="ms-4 quality-values-card-title-container quality-title">
                            <p className="mb-0 quality-values-card-title">
                                Empowering Your Brand in the Digital World
                            </p>
                        </div>
                        <div className="ms-4 pe-5 quality-text">
                            <p className="mb-0 quality-values-card-text">
                                We offer Search Engine Advertisement, Social Media Ads and Management and many more.
                            </p>
                        </div>
                        <div className="quality-values-card-arrow">
                            <img src={RightArrow} alt="right-arrow" width="20px" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                    <div className="d-flex quality-values-card">
                        <div className="mt-1 quality-plus">
                            <img src={Plus} alt="plus" />
                        </div>
                        <div className="ms-4 quality-values-card-title-container quality-title">
                            <p className="mb-0 quality-values-card-title">
                                Unlocking the Power of Digital to Drive Growth
                            </p>
                        </div>
                        <div className="ms-4 pe-5 quality-text">
                            <p className="mb-0 quality-values-card-text">
                                We help you achieve business success by optimising targeting the right audience through focused areas of your business.
                            </p>
                        </div>
                        <div className="quality-values-card-arrow">
                            <img src={RightArrow} alt="right-arrow" width="20px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalMarketingSliding;