import React from 'react'
import { Link } from 'react-router-dom'
import Img from "../../assets/img/about-banner.png"
import Img2 from "../../assets/img/data-int-big-2.png"

const DataIntelligenceRow = () => {
    return (
        <div className='container' style={{ padding: "100px 0" }}>
            <div className="row">
                <div className="col-xl-6">
                    <img src={Img} alt="" width="100%" />
                    <h3 className='text-blue py-4'>Get Ready with Admirise’s Predictive Modeling Service, Forecasting Trends, and Identifying Opportunities.</h3>
                    <p style={{ fontSize: "20px" }}>Admirise's data intelligence services (e.g. Dynamic Pricing) enhance your digital business decisions, boost customer experience, and increase your market competitiveness.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-xl-5 mt-3">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-6 mt-xl-0 mt-5">
                    <img src={Img2} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default DataIntelligenceRow;