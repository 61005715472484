import React from 'react'
import Plus from '../../assets/img/plus.png'
import Comment from '../../assets/img/comment.png'
import Service from '../../assets/img/services-hover-bg.png'
import Service2 from '../../assets/img/admirise-images/services-hover-bg-2.jpg'
import Service3 from '../../assets/img/admirise-images/services-hover-bg-3.jpg'
import Service4 from '../../assets/img/admirise-images/services-hover-bg-4.jpg'
import Service5 from '../../assets/img/admirise-images/services-hover-bg-5.jpg'
import Service6 from '../../assets/img/admirise-images/services-hover-bg-6.jpg'
import Service7 from '../../assets/img/admirise-images/services-hover-bg-7.jpg'
import Service8 from '../../assets/img/admirise-images/services-hover-bg-8.jpg'
import { Link } from 'react-router-dom'

const OurServicesCard = () => {
    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <div className="row mt-5">
            <div className="col-xl-3 col-6 px-0">
                <Link to='/digital-marketing' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Digital Marketing</h5>
                        <div className="services-hover-bg">
                            <img src={Service} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/e-commerce-management' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">E-commerce Management</h5>
                        <div className="services-hover-bg">
                            <img src={Service2} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/creative-content-design' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Creative Content Design</h5>
                        <div className="services-hover-bg">
                            <img src={Service3} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/website-development' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Website Development</h5>
                        <div className="services-hover-bg">
                            <img src={Service4} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/digital-security' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Digital Security</h5>
                        <div className="services-hover-bg">
                            <img src={Service5} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/data-intelligence' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Data Intelligence</h5>
                        <div className="services-hover-bg">
                            <img src={Service6} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/cloud-management' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Plus} alt="plus" />
                        <h5 className="mb-0">Cloud Management</h5>
                        <div className="services-hover-bg">
                            <img src={Service7} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-xl-3 col-6 px-0">
                <Link to='/contact' onClick={scrollTop}>
                    <div className="services-card p-3">
                        <img className="services-card-icon" src={Comment} alt="comment" />
                        <h5 className="mb-0">Get In Touch</h5>
                        <div className="services-hover-bg">
                            <img src={Service8} alt="" />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default OurServicesCard