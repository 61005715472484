import React from 'react'
import Img from "../../assets/img/cyber-touch.png"

const DigitalSecurityGetTouch = () => {
    return (
        <div className='container' style={{ padding: "100px 0" }}>
            <div className="row">
                <div className="col-xl-7 pe-xl-0">
                    <img src={Img} alt="" width="100%" className='h-100' />
                </div>
                <div className="col-xl-5 ps-xl-0">
                    <div className="get-in-touch-card h-100 " style={{ border: "1px solid #fff" }}>
                        GET IN <br />TOUCH
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalSecurityGetTouch;