import React from 'react'
import { Link } from 'react-router-dom'
import PlusBlue from "../../assets/img/plus-blue.png"
import Img from "../../assets/img/cloud-img-3.png"

const CloudManagementSeamless = () => {
    return (
        <div className='container mt-5'>
            <div className="row">
                <div className="col-xl-5">
                    <div className='d-flex'>
                        <div>
                            <img src={PlusBlue} alt="" />
                        </div>
                        <div className='ms-3'>
                            <h2 className='text-blue'>Seamless Migration <br /> Process</h2>
                        </div>
                    </div>
                    <p className='mt-3' style={{ fontSize: "22px" }}>
                        We provide a seamless migration process to move your data and systems to the cloud, such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Systems. Our team will work closely with you to assess your needs and recommend the best cloud solution for your business.
                    </p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-xl-5 mt-3">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-7 mt-xl-0 mt-4">
                    <img src={Img} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default CloudManagementSeamless;