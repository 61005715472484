import React from 'react'
import CareerBanner from '../components/Career/CareerBanner'
import CareerPositions from '../components/Career/CareerPositions'

const CareerInner = () => {
    return (
        <div id='career-container'>
            <div className="container">
                <CareerBanner />
                <CareerPositions />
            </div>
        </div>
    )
}

export default CareerInner;