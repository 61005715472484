import React from 'react'
import DataIntelligenceBanner from '../components/Services/DataIntelligenceBanner'
import DataIntelligenceBigCard from '../components/Services/DataIntelligenceBigCard'
import DataIntelligenceRow from '../components/Services/DataIntelligenceRow'
import DataIntelligenceServices from '../components/Services/DataIntelligenceServices'
import DataIntelligenceNumbers from '../components/Services/DataIntelligenceNumbers'
import DataIntelligenceAccordion from '../components/Services/DataIntelligenceAccordion'

const DataIntelligenceInner = () => {
    return (
        <div id='data-intelligence-container' className='p-xl-0 p-3'>
            <DataIntelligenceBanner />
            <DataIntelligenceBigCard />
            <DataIntelligenceRow />
            <DataIntelligenceServices />
            <DataIntelligenceNumbers />
            <DataIntelligenceAccordion />
        </div>
    )
}

export default DataIntelligenceInner