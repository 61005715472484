import React from 'react'
import Slider from "react-slick";

const ECommerceServices = () => {
    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container digital-marketing-services-container'>
            <h2>Our Services</h2>
            <Slider {...settings}>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Website Management</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our team manages your website, including security & compliance management, order & customer management, and data feed management.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Building Website Structure</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Building a high-performance e-commerce website tree structure and its professional management affects the navigation and organic traffic, as does your website.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Online Payment Integration</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Manuel payment acceptance is a time-consuming process. Automated online payment integration lets you accept payments directly within your software.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Prouct Information Management</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Admirise optimize your product listings to increase visibility and drive more sales. They use the latest SEO techniques and tools to ensure your product listings rank higher.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Report and Analyse</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide comprehensive reporting and analytic that will help you understand your business performance and identify areas for improvement.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Scalable Service</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our e-commerce management service scalable to meet the needs of businesses of any size. Whether you're a small startup or a large enterprise.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default ECommerceServices