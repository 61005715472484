import React from 'react'
import QualityValuesItem from './QualityValuesItem'

const QualityValues = () => {
    return (
        <div className='container-margin mb-5'>
            <div className="container">
                <h2 className="page-title">Quality Values</h2>
                <QualityValuesItem />
            </div>
        </div>
    )
}

export default QualityValues;