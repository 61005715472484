import React from 'react'
import { Link } from 'react-router-dom'
import Img1 from "../../assets/img/cloud-img-4.png"
import Img2 from "../../assets/img/cloud-img-5.png"
import Img3 from "../../assets/img/cloud-img-6.png"

const CloudManagementCards = () => {
    return (
        <div className='container mt-5'>
            <div className="row">
                <div className="col-xl-6">
                    <img src={Img1} alt="" width="100%" />
                    <h2 className='mt-4'>We Maintain and Manage Your <br /> On-Premise Systems, Ensuring <br /> They are Always Up-to-Date <br /> and Secure.</h2>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-6 mt-xl-0 mt-5">
                    <img src={Img2} alt="" width="100%" />
                    <h2 className='mt-4'>Your Data and Systems are <br /> Always Protected and Kept <br /> Confidential</h2>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="col-xl-6 mt-5">
                    <img src={Img3} alt="" width="100%" />
                    <h2 className='mt-4'>We've Got You Covered with Our <br /> Adaptable Cloud and On- <br /> Premise Systems Management <br /> Service</h2>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CloudManagementCards