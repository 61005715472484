import React from 'react'
import Desktop from '../../assets/img/desktop-outline.png'
import Square from '../../assets/img/big-sq.png'
import Line from '../../assets/img/vertical-line-big.png'

const ECommerceTable = () => {
    return (
        <div className='container e-commerce-table-container position-relative'>
            <div className="solid-website">
                <img src={Desktop} alt="desktop" />
                <h3 className='text-center mb-0 mt-4 text-white'>Solid Website <br /> Structure</h3>
            </div>
            <div className="design-layout">
                <img src={Desktop} alt="desktop" />
                <h3 className='text-center mb-0 mt-4 text-white'>Design and <br /> Layout</h3>
            </div>
            <div className="better-navigation">
                <img src={Desktop} alt="desktop" />
                <h3 className='text-center mb-0 mt-4 text-white'>Better <br /> Navigation</h3>
            </div>
            <div className="optimized-website">
                <img src={Desktop} alt="desktop" />
                <h3 className='text-center mb-0 mt-4 text-white'>Optimized <br /> Website</h3>
            </div>
            <img src={Square} alt="square" className='e-commerce-table-square' />
            <img src={Line} alt="Line" className='e-commerce-table-line' />
        </div>
    )
}

export default ECommerceTable;