import React from 'react'
import CaseStudiesCard from './CaseStudiesCard'

const CaseStudies = () => {
    return (
        <div className='case-studies'>
            <div className="container">
                <h2 className='page-title'>Case Studies</h2>
            </div>
            <CaseStudiesCard />
        </div>
    )
}

export default CaseStudies