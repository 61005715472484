import React from 'react'
import Banner from '../../assets/img/about-banner.png'

const AboutBanner = () => {
    return (
        <div>
            <img src={Banner} alt="banner" width='100%' />
        </div>
    )
}

export default AboutBanner;