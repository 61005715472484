import React from 'react'
import Img from "../../assets/img/digital-sec-3.png"
import Img2 from "../../assets/img/digital-sec-4.png"
import Plus from "../../assets/img/plus.png"

const DigitalSecurityAttached = () => {
    return (
        <div style={{ padding: "100px 0 0 0" }}>
            <div className="row gx-0">
                <div className="col-xl-6">
                    <div className='position-relative'>
                        <img src={Img} alt="" width="100%" />
                        <div className='attached-text'>
                            <div className="d-flex align-items-baseline attached-absolute-txt">
                                <img src={Plus} alt="" />
                                <p className='ms-3 ps-xl-0 ps-3'>Stay Secure Online with Our Website Security Service</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 mt-xl-0 mt-4">
                    <div className='position-relative'>
                        <img src={Img2} alt="" width="100%" />
                        <div className='attached-text'>
                            <div className="d-flex align-items-baseline attached-absolute-txt">
                                <img src={Plus} alt="" />
                                <p className='ms-3 ps-xl-0 ps-3'>We Help You Protect Your Customer’s Information</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalSecurityAttached