import React from "react";
import Banner from "../components/Homepage/Banner";
import OurServices from "../components/Homepage/OurServices";
import AdvantagesAdm from "../components/Homepage/AdvantagesAdm";
import MarqueeText from "../components/Homepage/MarqueeText";
import CaseStudies from "../components/Homepage/CaseStudies";
import QualityValues from "../components/Homepage/QualityValues";
import Customers from "../components/Homepage/Customers";
import BannerSlider from "../components/Homepage/BannerSlider";

const Homepage = () => {
  return (
    <div id="homepage">
      <BannerSlider />
      <OurServices />
      <AdvantagesAdm />
      <MarqueeText />
      <CaseStudies />
      <Customers />
      <QualityValues />
    </div>
  );
};

export default Homepage;
