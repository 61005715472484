import React, { useState, useEffect } from "react";
import Logo from "../assets/img/logo.png";
import Language from "../assets/img/lang-icon.png";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Hamburger from "../assets/img/hamburger-bars.png";
import PlusBlack from "../assets/img/plus-black.png";

const Header = () => {
  const [active, isActive] = useState(false);
  const [activeMobile, isActiveMobile] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [lang, setLang] = useState("tr");
  const [menuObj, setMenuObj] = useState({
    tr: [
      { id: 1, text: "Anasayfa", url: "/" },
      { id: 2, text: "Biz Kimiz?", url: "/biz-kimiz" },
      { id: 3, text: "Ekibimiz", url: "/ekibimiz" },
      { id: 4, text: "İletişim", url: "/iletisim" },
      { id: 5, text: "Hizmetler", url: "/hizmetler" },
    ],
    en: [
      { id: 1, text: "Home", url: "/" },
      { id: 2, text: "Who are we?", url: "/who-are-we" },
      { id: 3, text: "Our team", url: "/our-team" },
      { id: 4, text: "Contact", url: "/contact" },
      { id: 5, text: "Services", url: "/services" },
    ],
  });
  const [menu, setMenu] = useState([
    { id: 1, text: "Anasayfa", url: "/" },
    { id: 2, text: "Biz Kimiz?", url: "/biz-kimiz" },
    { id: 3, text: "Ekibimiz", url: "/ekibimiz" },
    { id: 4, text: "İletişim", url: "/iletisim" },
    { id: 5, text: "Hizmetler", url: "/hizmetler" },
  ]);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1200px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 99);
    });

    setMenu([...menuObj[lang]]);
    console.log(menu);
  }, []);

  const dropdownMenu = () => {
    isActive(!active);
  };

  const changeLang = () => {
    if (lang === "en") {
      setLang("tr");
    } else if (lang === "tr") {
      setLang("en");
    }

    setMenu([...menuObj[lang]]);
    console.log(menu);
  };

  const scrollTopDropClose = () => {
    window.scrollTo(0, 0);
    isActive(false);
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const mobileMenuClick = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const dropdownMenuMobile = () => {
    isActiveMobile(!activeMobile);
  };

  return (
    <>
      {matches && (
        <section
          id="header"
          className={`shadow-sm border-bottom-black ${
            scroll ? "header-transparent" : ""
          }`}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="header-logo-animation">
                <Link to="/" onClick={scrollTop}>
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <div className="navbar-menu">
                <ul className="list-unstyled mb-0 d-flex align-items-center">
                  <li>
                    <NavLink to="/" onClick={scrollTop}>
                      {menu[0].text}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/products" onClick={scrollTop}>
                      {menu[1].text}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us" onClick={scrollTop}>
                      {menu[2].text}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/career" onClick={scrollTop}>
                      {menu[3].text}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/blog" onClick={scrollTop}>
                      Blog
                    </NavLink>
                  </li> */}
                  <li
                    className={`services-toggle-btn ${active ? "active" : ""}`}
                  >
                    <div
                      onClick={dropdownMenu}
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    >
                      {menu[4].text}
                    </div>
                    <div className="services-dropdown">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-xl-5"
                            style={{ borderRight: "1px solid #aaa" }}
                          >
                            <div className="w-75">
                              <h3 className="mb-3" style={{ color: "#000" }}>
                                Our Services
                              </h3>
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                We drive and promote your value, make quick and
                                confident business decisions, and continuosly
                                improve your online business.
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-7 ps-5">
                            <div className="row navbar-dropdown-list">
                              <div className="col-xl-5">
                                <ul className="list-unstyled">
                                  <li
                                    className="mb-4"
                                    onClick={scrollTopDropClose}
                                  >
                                    <NavLink to="/digital-marketing">
                                      Digital Marketing
                                    </NavLink>
                                  </li>
                                  <li
                                    className="mb-4"
                                    onClick={scrollTopDropClose}
                                  >
                                    <NavLink to="/e-commerce-management">
                                      E-commerce Management
                                    </NavLink>
                                  </li>
                                  <li
                                    className="mb-4"
                                    onClick={scrollTopDropClose}
                                  >
                                    <NavLink to="/creative-content-design">
                                      Creative Content Design
                                    </NavLink>
                                  </li>
                                  <li className="" onClick={scrollTopDropClose}>
                                    <NavLink to="/website-development">
                                      Website Development
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-xl-4">
                                <ul className="list-unstyled">
                                  <li
                                    className="mb-4"
                                    onClick={scrollTopDropClose}
                                  >
                                    <NavLink to="/digital-security">
                                      Digital Security
                                    </NavLink>
                                  </li>
                                  <li
                                    className="mb-4"
                                    onClick={scrollTopDropClose}
                                  >
                                    <NavLink to="/data-intelligence">
                                      Data Intelligence
                                    </NavLink>
                                  </li>
                                  <li className="" onClick={scrollTopDropClose}>
                                    <NavLink to="/cloud-management">
                                      Cloud Management
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <img src={Language} alt="language-icon" className="me-2" />{" "}
                    <span
                      onClick={changeLang}
                      style={{ cursor: "pointer", textTransform: "uppercase" }}
                    >
                      {lang}
                    </span>
                  </li>
                  <NavLink to="/contact" onClick={scrollTop}>
                    <button className="btn btn-outline-blue py-1 px-3 buttonfx curtaindown">
                      Contact Us{" "}
                      <i className="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                  </NavLink>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
      {!matches && (
        <section id="mobileHeader" className="py-5">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <NavLink className="text-dark" to="/" onClick={scrollTop}>
                  <img src={Logo} alt="logo" />
                </NavLink>
              </div>
              <div>
                <button
                  className="btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <img src={Hamburger} alt="hamburger-menu" width="32px" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <div>
                <NavLink to="/" onClick={mobileMenuClick}>
                  <img src={Logo} alt="logo" />
                </NavLink>
              </div>
              <h5 className="offcanvas-title" id="offcanvasRightLabel" />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body mt-4">
              <ul className="list-unstyled">
                <li>
                  <NavLink
                    className="text-dark d-flex align-items-center justify-content-between w-100"
                    to="/"
                    onClick={dropdownMenuMobile}
                  >
                    Services{" "}
                    <img
                      src={PlusBlack}
                      alt=""
                      width="30px"
                      className="text-end"
                    />
                  </NavLink>
                  {activeMobile ? (
                    <ul className="mobile-dropdown-services-li">
                      <li>
                        <NavLink
                          to="/digital-marketing"
                          onClick={mobileMenuClick}
                        >
                          Digital Marketing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/e-commerce-management"
                          onClick={mobileMenuClick}
                        >
                          E-commerce Management
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/creative-content-design"
                          onClick={mobileMenuClick}
                        >
                          Creative Content Design
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/website-development"
                          onClick={mobileMenuClick}
                        >
                          Website Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/digital-security"
                          onClick={mobileMenuClick}
                        >
                          Digital Security
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/data-intelligence"
                          onClick={mobileMenuClick}
                        >
                          Data Intelligence
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/cloud-management"
                          onClick={mobileMenuClick}
                        >
                          Cloud Management
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </li>
                <li>
                  <NavLink
                    className="text-dark"
                    to="/case-studies"
                    onClick={mobileMenuClick}
                  >
                    Case Studies
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-dark"
                    to="/products"
                    onClick={mobileMenuClick}
                  >
                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-dark"
                    to="/about-us"
                    onClick={mobileMenuClick}
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-dark"
                    to="/career"
                    onClick={mobileMenuClick}
                  >
                    Career
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="text-dark"
                    to="/blog"
                    onClick={mobileMenuClick}
                  >
                    Blog
                  </NavLink>
                </li>
                <Link to="/contact">
                  <button className="btn btn-outline-blue py-1 px-3 buttonfx curtaindown mt-4">
                    Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                  </button>
                </Link>
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Header;
