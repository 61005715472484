import React from 'react'
import ECommerceBanner from '../components/Services/ECommerceBanner'
import ECommerceCard from '../components/Services/ECommerceCard'
import ECommerceTable from '../components/Services/ECommerceTable'
import ECommerceServices from '../components/Services/ECommerceServices'
import ECommerceInfoCard from '../components/Services/ECommerceInfoCard'
import ECommerceAccordion from '../components/Services/ECommerceAccordion'

const ECommerceManagementInner = () => {
    return (
        <div id='e-commerce-marketing-container' className='p-3'>
            <ECommerceBanner />
            <ECommerceCard />
            <ECommerceTable />
            <ECommerceServices />
            <ECommerceInfoCard />
            <ECommerceAccordion />
        </div>
    )
}

export default ECommerceManagementInner;