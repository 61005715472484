import React from 'react'
import BlogSmall1 from '../../assets/img/blog-small-1.png'
import BlogSmall2 from '../../assets/img/blog-small-2.png'
import BlogSmall3 from '../../assets/img/blog-small-3.png'
import Blog1 from '../../assets/img/blog-1.png'
import Blog2 from '../../assets/img/blog-2.png'
import Blog3 from '../../assets/img/blog-3.png'
import Blog4 from '../../assets/img/blog-4.png'
import Blog5 from '../../assets/img/blog-5.png'
import Blog6 from '../../assets/img/blog-6.png'

const BlogContent = () => {
    return (
        <div className='blog-content-container m-xl-0 m-3'>
            <div className="row">
                <div className="col-xl-2">
                    <div className="blog-small-card">
                        <img src={BlogSmall1} alt="blog" />
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-0 mt-5">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog1} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>How to Choose the...</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-0 mt-5">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog2} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>SEO Meets Psychology</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-5 mt-xl-5 mt-5">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog3} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>Maximize the...</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-5 mt-5">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog4} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>InHouse vs Agency</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 mt-xl-5">
                    <div className="blog-small-card">
                        <img src={BlogSmall2} alt="blog" />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-xl-2">
                    <div className="blog-small-card">
                        <img src={BlogSmall3} alt="blog" />
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-0 mt-0">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog5} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>A Case Study</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 mt-xl-0 mt-5">
                    <div className="case-card blog-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Blog6} alt="blog" width="100%" />
                        </div>
                        <div className="case-card-body blog-card-body p-4">
                            <h3>Machine Learning in...</h3>
                            <p className="mb-0">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, blanditiis.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogContent