import React from 'react'
import ContactBanner from '../components/Contact/ContactBanner'
import ContactForm from '../components/Contact/ContactForm'
import ContactPerson from '../components/Contact/ContactPerson'

const ContactInner = () => {
    return (
        <div id='contant-container'>
            <div className="container">
                <ContactBanner />
                <ContactForm />
                <ContactPerson />
            </div>
        </div>
    )
}

export default ContactInner;