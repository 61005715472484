import React from 'react'
import DigitalMarketingBanner from '../components/Services/DigitalMarketingBanner';
import DigitalMarketingGetTouch from '../components/Services/DigitalMarketingGetTouch';
import DigitalMarketingDriveResults from '../components/Services/DigitalMarketingDriveResults';
import DigitalMarketingServices from '../components/Services/DigitalMarketingServices';
import DigitalMarketingBannerTwo from '../components/Services/DigitalMarketingBannerTwo';
import DigitalMarketingSliding from '../components/Services/DigitalMarketingSliding';
import DigitalMarketingAccordion from '../components/Services/DigitalMarketingAccordion';

const DigitalMarketing = () => {
    return (
        <div id='digital-marketing-container' className='p-xl-0 p-3'>
            <DigitalMarketingBanner />
            <DigitalMarketingGetTouch />
            <DigitalMarketingDriveResults />
            <DigitalMarketingServices />
            <DigitalMarketingBannerTwo />
            <DigitalMarketingSliding />
            <DigitalMarketingAccordion />
        </div>
    )
}

export default DigitalMarketing;