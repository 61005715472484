import React from 'react'
import LeftImg from '../../assets/img/website-left.png'
import MidImg from '../../assets/img/website-middle.png'
import RightImg from '../../assets/img/website-right.png'
import Plus from '../../assets/img/plus.png'

const WebsiteQuote = () => {
    return (
        <div className='website-quote'>
            <div className="row gx-0 first-row">
                <div className="col-xl-4">
                    <img src={LeftImg} alt="" width='100%' />
                </div>
                <div className="col-xl-4 website-quote-mobile-img">
                    <img src={MidImg} alt="" width='100%' />
                </div>
                <div className="col-xl-4 website-quote-mobile-img">
                    <img src={RightImg} alt="" width='100%' />
                </div>
            </div>
            <div className="row gx-0 second-row justify-content-between align-items-center">
                <div className="col-xl-8">
                    <div className="row p-xl-0 p-4">
                        <div className="col-xl-1">
                            <img src={Plus} alt="" />
                        </div>
                        <div className="col-xl-11">
                            <p className='mb-0 second-row-text'>Creating SEO and User-Friendly (UX), Mobile Responsive Website with the Latest Technologies Drives Busienss Grows</p>
                            <div>
                                <p className='second-row-sm-text mt-4'>
                                    By choosing our website development service, you can have a prominent online presence tailored to your specific needs and goals, improve your business performance, increase efficiency, and reduce costs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 text-end website-quote-btn" style={{ paddingRight: '125px' }}>
                    <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                        Get Quote <i className="fa-solid fa-arrow-right ms-2" />
                    </button>
                </div>
            </div>
            <div className='contact-form-submit-btn website-quote-btn-mobile'>
                <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown mt-4">
                    Get Quote <i className="fa-solid fa-arrow-right ms-2" />
                </button>
            </div>
        </div>
    )
}

export default WebsiteQuote