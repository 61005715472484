import React from 'react'
import AboutBanner from '../components/AboutUs/AboutBanner'
import AboutContent from '../components/AboutUs/AboutContent'

const AboutUsInner = () => {
    return (
        <div id='about-us-container'>
            <AboutBanner />
            <AboutContent />
        </div>
    )
}

export default AboutUsInner;