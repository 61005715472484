import React from 'react'
import Slider from "react-slick";
import Case1 from '../../assets/img/case-img.png'
import Case2 from '../../assets/img/case-img-2.png'
import Case3 from '../../assets/img/case-3.png'
import { Link } from "react-router-dom"

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <i className="fa-solid fa-arrow-right fa-2x case-next-arrow" onClick={onClick}></i>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow" onClick={onClick}></i>
    );
}

const scrollTop = () => {
    window.scrollTo(0, 0);
}

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        }
    ]
};

const CaseStudiesCard = () => {
    return (
        <div className='container homepage-slider-case-studies'>
            <Slider {...settings}>
                <div className="p-xl-3 p-0">
                    <div className="case-card position-relative">
                        <div className="case-card-img-container">
                            <img src={Case1} alt="case-img" width="100%" />
                        </div>
                        <div className="case-card-hover d-flex">
                            <div className="case-card-hover-badge">
                                SEO
                            </div>
                            <div className="case-card-hover-badge ms-3">
                                SEM
                            </div>
                        </div>
                        <div className="case-card-body p-4">
                            <h3>Chef's Deal</h3>
                            <p className="mb-0">
                                Chef's Deal is a company that sells its products both in-store, over the
                                phone,
                                and on its
                                website.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="p-xl-3 p-0">
                    <div className="case-card position-relative">
                        <img src={Case2} alt="case-img" width="100%" />
                        <div className="case-card-hover d-flex">
                            <div className="case-card-hover-badge">
                                SEO
                            </div>
                            <div className="case-card-hover-badge ms-3">
                                SEO &amp; Local SEO
                            </div>
                        </div>
                        <div className="case-card-body p-4">
                            <h3>CabinetLand</h3>
                            <p className="mb-0">
                                Cabinetland is a local company that specializes in kitchen and bathroom remodeling.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="p-xl-3 p-0">
                    <div className="case-card position-relative">
                        <img src={Case3} alt="case-img" width="100%" />
                        <div className="case-card-hover d-flex">
                            <div className="case-card-hover-badge">
                                Web Development
                            </div>
                            <div className="case-card-hover-badge ms-3">
                                SEO &amp; SEM
                            </div>
                        </div>
                        <div className="case-card-body p-4">
                            <h3>Tampap</h3>
                            <p className="mb-0">
                                At the time of their application, Tampap operated exclusively within marketplaces for product sales.
                            </p>
                            <button className="btn btn-outline-blue buttonfx py-3 px-4 curtaindown mt-3">
                                Read More <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className='text-end p-xl-3 p-0 mt-xl-0 mt-3 case-studies-all-btn'>
                <Link to="/case-studies" onClick={scrollTop}>
                    <button className="btn btn-outline-blue py-3 px-4 buttonfx curtaindown">
                        See All <i className="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default CaseStudiesCard;