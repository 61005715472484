import React from 'react'
import AdvantagesAdmAccordion from './AdvantagesAdmAccordion'

const AdvantagesAdm = () => {
    return (
        <div className='container-margin'>
            <div className="container">
                <h2 className='page-title'>Advantages of Working With Admirise</h2>
            </div>
            <AdvantagesAdmAccordion />
        </div>
    )
}

export default AdvantagesAdm