import React from 'react'
import Slider from "react-slick";

const DigitalSecuritySecurity = () => {
    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container digital-marketing-services-container'>
            <h2>Our Services</h2>
            <Slider {...settings}>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Network Security</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We ensure your network is protected from cyber-attacks. We use the latest security technologies and techniques to detect and prevent threats.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Website Security</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                It is essential for protecting your online business from potential security breaches. Our website security service includes regular security updates.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Payment Security</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                It is critical for protecting your customers' sensitive information. Our payment security service includes compliance with industry standards and regulations.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Cyber Security</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                In the event of a cyber security incident, our cyber security incident response service ensures that your business is resilient against cyber attacks.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Penetration Testing</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                Our team works with you to identift potential vulerabilities in your system and provide recommendations for remediation.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Disaster Recovery</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>
                                We provide a comprehensive disaster recovery plan to ensure your business is ready for cyber incidents.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default DigitalSecuritySecurity