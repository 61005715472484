import React from 'react'
import { Link } from 'react-router-dom'

const DigitalSecurityBanner = () => {
    return (
        <div className="container digital-marketing-banner">
            <div className='row'>
                <div className="col-xl-5">
                    <h1 className='career-banner-title'>Digital <br /> <span className='security-span'>Security</span></h1>
                </div>
                <div className="col-xl-7 mt-xl-0 mt-4">
                    <p className='career-banner-text'>
                        Digital security is significant for the success and sustainability of your online business. With the increasing number of cyber threats, protecting your business from potential security breaches is more important than ever.
                    </p>
                    <div className='contact-form-submit-btn'>
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalSecurityBanner;