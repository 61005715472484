import React from 'react'
import Plus from '../../assets/img/plus-black.png'
import Image from '../../assets/img/admirise-images/services-hover.jpg'
import Image2 from '../../assets/img/admirise-images/services-hover-2.jpg'

const ECommerceCard = () => {
    return (
        <div className='container'>
            <div className="e-commerce-card position-relative">
                <div className="row">
                    <div className="col-xl-1">
                        <img src={Plus} alt="plus" className='e-commerce-plus' />
                    </div>
                    <div className="col-xl-5">
                        <p className='e-commerce-card-text mb-0'>Innovating E-commerce Solutions for Maximum Success in B2B and B2C Environments</p>
                    </div>
                    <div className='e-commerce-card-image'>
                        <img src={Image} alt="image" />
                    </div>
                </div>
            </div>
            <div className="e-commerce-card position-relative mt-5">
                <div className="row">
                    <div className="col-xl-1">
                        <img src={Plus} alt="plus" className='e-commerce-plus' />
                    </div>
                    <div className="col-xl-5">
                        <p className='e-commerce-card-text mb-0'>Unique E-Commerce Solutions to Achieve Your Goals with Our Expert Guidance and Data-Driven Strategies</p>
                    </div>
                    <div className='e-commerce-card-image'>
                        <img src={Image2} alt="image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ECommerceCard;