import React from 'react'
import Slider from "react-slick";

const DigitalMarketingServices = () => {

    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container digital-marketing-services-container'>
            <h2>Our Services</h2>
            <Slider {...settings}>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Digital Marketing Consultancy</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>Where we work with you to understand your business goals and develop a tailored digital marketing strategy to achieve them.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Search Engine Optimization</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>To help your website higher in search engine results: Our teams optimise your website and content to increase visibility and drive more traffic.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Content Marketing</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>To create valuable and engaging content for your audience: Admirise teams work with you to create engaging and informative content that will attract and retain customers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Search Engine Advertisement</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>Is a powerful tool for driving traffic and sales, driving targeted traffic to your website. Our teams create and manage effective campaigns to put your products and services in front of the right audience.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Social Media Ads and Management</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>To promote your brand and products on social media platforms: Social media ads and management are essential for building brand awareness and engaging with the right customers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                    <div className="p-3 d-marketing-card">
                        <div className='text'>
                            <h4>Brand Awareness</h4>
                            <p className='mb-0' style={{ fontSize: '18px', fontWeight: '500' }}>Managing online visibility and other digital marketing fields can directly affect revenue and brand awareness. At the same time, reputation management reduces the damage, builds credibility, and increases higher ranking.
                            </p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default DigitalMarketingServices