import React from 'react'
import BlogContent from '../components/Blog/BlogContent'

const BlogInner = () => {
    return (
        <div id='blog-container'>
            <div className="container blog-container">
                <h1 className='blog-title'>Blog</h1>
                <BlogContent />
            </div>
        </div>
    )
}

export default BlogInner