import React from 'react'

const CreativeContentAccordion = () => {
    return (
        <div id='digital-marketing-accordion'>
            <div className="container">
                <h2 style={{ fontWeight: '400' }}>Read more about</h2>
                <h2 style={{ fontWeight: '500' }}>Creative Content Design</h2>
            </div>
            <div>
                <div className="accordion mt-5" id="accordionExample">
                    <div className="accordion-item d-m-accordion">
                        <h2 className="accordion-header">
                            <button className="accordion-button container collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <div className>
                                    <div className="accordion-mobile-text-container">
                                        <div className="accordion-text-big">
                                            <div>Web Site Management</div>
                                        </div>
                                    </div>
                                    <div className="accordion-mobile-text mt-2">
                                        <p className="mb-0">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corporis aspernatur earum nostrum incidunt illo ex doloremque, adipisci cum delectus vel, explicabo, eligendi possimus. Ipsam eos sint error iusto? In iusto laboriosam distinctio, dolorem quod perspiciatis voluptates et est. Minus saepe officia voluptates laborum voluptatibus omnis ab, maiores corrupti eaque ex!
                                        </p>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="container px-0">
                                    <p className="mb-0" style={{ fontSize: '24px' }}>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio quos voluptatum blanditiis tempora ratione totam cupiditate a earum deleniti culpa officia illum amet quaerat dolore modi reiciendis perferendis rerum, dolorem corporis quibusdam, minus laborum, unde perspiciatis? Saepe itaque officia maxime ad ullam dignissimos dolorum laudantium dolor eius, facere rem facilis.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item d-m-accordion">
                        <h2 className="accordion-header">
                            <button className="accordion-button container collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div className>
                                    <div className="accordion-mobile-text-container">
                                        <div className="accordion-text-big">
                                            <div>Building Website Structure</div>
                                        </div>
                                    </div>
                                    <div className="accordion-mobile-text mt-2">
                                        <p className="mb-0">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil reprehenderit dolores deserunt corrupti esse sed quo tenetur neque assumenda cum ipsa et cupiditate fugit quod dolorum, aspernatur amet beatae doloremque odio! Natus pariatur rerum labore magni, facilis doloribus, commodi cum minus quis accusantium perferendis omnis blanditiis! Necessitatibus, nulla. Obcaecati, provident.
                                        </p>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="container px-0">
                                    <p className="mb-0" style={{ fontSize: '24px' }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus quos, possimus architecto soluta veniam fugit maiores eaque facilis ipsam, velit amet, nesciunt reiciendis tenetur quisquam earum sed? Repudiandae quod pariatur culpa alias quibusdam illo minima odit vero. Minus, veritatis. Cum voluptatum quos ullam magni, quis quisquam iure unde vitae excepturi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreativeContentAccordion