import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick"
import Line from "../../assets/img/line.png"

const CreativeContentSlider = () => {
    const SampleNextArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-right fa-2x case-next-arrow d-marketing-arrow creative-arrow" onClick={onClick}></i>
        );
    }

    const SamplePrevArrow = (props) => {
        const { onClick } = props;
        return (
            <i className="fa-solid fa-arrow-left fa-2x case-prev-arrow d-marketing-arrow creative-arrow" onClick={onClick}></i>
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='creative-content-slider-container p-xl-0 p-3'>
            <div className="container">
                <h2 className='fw-normal'>We Have the Solution for <br />Your Business</h2>
                <Slider {...settings} className='mt-5'>
                    <div className='p-4 creative-slider-item'>
                        <div className='d-flex align-items-center'>
                            <div className='me-3' style={{ fontSize: "32px" }}>01</div> <img src={Line} alt="" />
                        </div>
                        <h3 className='fw-normal' style={{ fontSize: "32px" }}>Graphic Design</h3>
                        <p style={{ fontSize: "22px" }}>We offer visually appealing designs for your website, social media, and marketing materials.</p>
                    </div>
                    <div className='p-4 creative-slider-item'>
                        <div className='d-flex align-items-center'>
                            <div className='me-3' style={{ fontSize: "32px" }}>02</div> <img src={Line} alt="" />
                        </div>
                        <h3 className='fw-normal' style={{ fontSize: "32px" }}>Photography</h3>
                        <p style={{ fontSize: "22px" }}>Our team of photographers creates images that reflect your brand and help you maximize conversions.</p>
                    </div>
                    <div className='p-4 creative-slider-item'>
                        <div className='d-flex align-items-center'>
                            <div className='me-3' style={{ fontSize: "32px" }}>03</div> <img src={Line} alt="" />
                        </div>
                        <h3 className='fw-normal' style={{ fontSize: "32px" }}>Video Production</h3>
                        <p style={{ fontSize: "22px" }}>We produce high-quality videos to help you tell your story and engage your target audience at Admirise.</p>
                    </div>
                    <div className='p-4 creative-slider-item'>
                        <div className='d-flex align-items-center'>
                            <div className='me-3' style={{ fontSize: "32px" }}>04</div> <img src={Line} alt="" />
                        </div>
                        <h3 className='fw-normal' style={{ fontSize: "32px" }}>Motion Graphic Design</h3>
                        <p style={{ fontSize: "22px" }}>We create stunning and engaging animation and videos that will help you communicate effectively.</p>
                    </div>
                    <div className='p-4 creative-slider-item'>
                        <div className='d-flex align-items-center'>
                            <div className='me-3' style={{ fontSize: "32px" }}>05</div> <img src={Line} alt="" />
                        </div>
                        <h3 className='fw-normal' style={{ fontSize: "32px" }}>2D Animation Design</h3>
                        <p style={{ fontSize: "22px" }}>We offer visually appealing designs for your website, social media, and marketing materials.</p>
                    </div>
                </Slider>
                <div className='text-end mt-4 contact-form-submit-btn creative-slider-btn'>
                    <Link to='/contact'>
                        <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                            Contact Us <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CreativeContentSlider;