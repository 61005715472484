import React from 'react'
import Pin from '../../assets/img/pin-outline-white.png'
import Call from '../../assets/img/call-white.png'
import Mail from '../../assets/img/mail-outline-white.png'
import Logo from '../../assets/img/logo-white.png'

const ContactForm = () => {
    return (
        <div className='row contact-form'>
            <div className="col-xl-6 px-0">
                <div className="form-left-card d-flex align-items-end h-100 p-5">
                    <div className="row align-items-end">
                        <div className="col-xl-7">
                            <div className='form-left-card-text'>
                                <div className='mb-4'>
                                    <img src={Pin} alt="location" />
                                    <span className='ms-3'>Berner Straße 109, 60437 Frankfurt am Main</span>
                                </div>
                                <div className='mb-4'>
                                    <img src={Call} alt="call" />
                                    <span className='ms-3'>+49 069 17427233</span>
                                </div>
                                <div>
                                    <img src={Mail} alt="mail" />
                                    <span className='ms-3'>info@admirise.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 text-end mt-xl-0 mt-5">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 px-0">
                <div className="p-5">
                    <div className="row">
                        <div className="col-xl-6">
                            <input type="text" className='form-control' placeholder='Name' />
                        </div>
                        <div className="col-xl-6 mt-xl-0 mt-4">
                            <input type="text" className='form-control' placeholder='E-Mail/Phone Number' />
                        </div>
                        <div className="col-xl-12 mt-4">
                            <input type="text" className='form-control' placeholder='Subject' />
                        </div>
                        <div className="col-xl-12 mt-4">
                            <textarea rows="5" className='form-control' placeholder='Message'></textarea>
                        </div>
                    </div>
                    <div className='text-end mt-xl-5 mt-4 contact-form-submit-btn'>
                        <button className="btn btn-outline-blue py-3 px-4 buttonfx curtaindown">
                            Submit <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;