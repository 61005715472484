import React from 'react'
import RightArrow from '../../assets/img/right-arrow.png'
import Plus from '../../assets/img/plus-black.png'

const QualityValuesItem = () => {
    return (
        <div className="row mt-5">
            <div className="col-xl-12" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Comprehensive Technology and Methodology
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            Admirise uses advanced methodologies and digital marketing and SEO technologies
                            (techniques) always to stay ahead of your competitors.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Innovative &amp; Experienced Team
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            Admirise brings effective and innovative digital marketing and the IT team together to
                            impact the successful process significantly.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Excellent Customer Satisfaction
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            We see our customers as partners, and by focusing on their goals and expectations, we
                            achieve excellent success.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Integrity &amp; Accountability
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            Admirise is accountable for signifying the growth metrics with big data.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Fast and Quality Service
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            With our service in the corner, we deliver the right products or services that meet our
                            customers' needs at the right time.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mt-5" style={{ overflow: 'hidden' }}>
                <div className="d-flex quality-values-card">
                    <div className="mt-1 quality-plus">
                        <img src={Plus} alt="plus" />
                    </div>
                    <div className="ms-4 quality-values-card-title-container quality-title">
                        <p className="mb-0 quality-values-card-title">
                            Excellent Communication
                        </p>
                    </div>
                    <div className="ms-4 pe-5 quality-text">
                        <p className="mb-0 quality-values-card-text">
                            We include our customers in the project development processes and meet their needs fully
                            by considering different perspectives.
                        </p>
                    </div>
                    <div className="quality-values-card-arrow">
                        <img src={RightArrow} alt="right-arrow" width="20px" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QualityValuesItem;