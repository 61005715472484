import React from 'react'

const AboutContent = () => {
    return (
        <div className='container'>
            <div className='about-content-item'>
                <h2 className='text-blue'>About Admirise</h2>
                <p className='about-text'>Admirise is a young company established by a team of experts experienced in their fields for more than 15 years. We have achieved many successful IT, data, and digital business projects. Our expert team takes all responsibility at a single point by providing business intelligence, data analysis, data engineering, web/mobile software development, SEO, and digital marketing services in E-commerce/digital businesses.</p>
            </div>
            <div className='about-content-item'>
                <h2 className='text-blue'>What We Offer</h2>
                <p className='about-text'>Admirise provides all these services to optimize your online business, create strong brand awareness, and increase your online and local turnover. We ensure our clients' online businesses keep rising and meet the right audiences at the right time. Admirise, therefore, becomes a one-stop-station for all layers of the E-commerce/digital business with these essentially integrated services.</p>
            </div>
            <div className='about-content-item'>
                <h2 className='text-blue'>What Is 360 Degree E-comerce?</h2>
                <p className='about-text'>By providing 360-degree E-commerce and digital business consultancy services, Admirise:</p>
                <ul className='about-text'>
                    <li>Communicates with our clients in an always-open channel,</li>
                    <li>Focuses on full-pitch online pressure in all essential channels,</li>
                    <li>Develops a unique digital strategy for each business with our unique digital funneling methodology,</li>
                    <li>Provides your online business with an '<span style={{ fontWeight: '600' }}>Admirable Rise.</span>'</li>
                </ul>
            </div>
            <div className='about-content-item'>
                <h2 className='text-blue'>How We Approach</h2>
                <p className='about-text'>Each business is unique and needs a specially developed and timely implemented digital strategy. We are experienced in the fact that the digital approach should be multi-dimensional and applicable, and Admirise can take your business to the next level as soon as possible.</p>
            </div>
            <div className="about-content-item">
                <p className='about-last-text'>Admirise can eliminate all barriers against your invaluable business, turn your marketing strategy in the right direction and increase your business power in all online channels. With its accurate and fast decisions on time, Admirise continuously improves your digital business, augments your earnings, and brings your deserved prestige.</p>
            </div>
        </div>
    )
}

export default AboutContent;