import "./App.css";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Homepage from "./pages/Homepage";
import CaseStudiesInner from "./pages/CaseStudiesInner";
import AboutUsInner from "./pages/AboutUsInner";
import CareerInner from "./pages/CareerInner";
import BlogInner from "./pages/BlogInner";
import ContactInner from "./pages/ContactInner";
import DigitalMarketingInner from "./pages/DigitalMarketingInner";
import ECommerceManagementInner from "./pages/ECommerceManagementInner";
import WebsiteDevelopment from "./pages/WebsiteDevelopment";
import DigitalSecurity from "./pages/DigitalSecurity";
import DataIntelligenceInner from "./pages/DataIntelligenceInner";
import CloudManagement from "./pages/CloudManagement";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImpressumInner from "./pages/ImpressumInner";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductsDesignerInner from "./pages/ProductsDesignerInner";
import MachineLearningDetail from "./pages/MachineLearningDetail";
import CreativeContentDesign from "./pages/CreativeContentDesign";
import CareerDetail from "./components/Career/CareerDetail";
import ProductsInner from "./components/Products/ProductsInner";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/case-studies" element={<CaseStudiesInner />} />
        <Route path="/products" element={<ProductsInner />} />
        <Route path="/about-us" element={<AboutUsInner />} />
        <Route path="/career" element={<CareerInner />} />
        <Route path="/career-detail" element={<CareerDetail />} />
        <Route path="/blog" element={<BlogInner />} />
        <Route path="/contact" element={<ContactInner />} />
        <Route path="/digital-marketing" element={<DigitalMarketingInner />} />
        <Route
          path="/e-commerce-management"
          element={<ECommerceManagementInner />}
        />
        <Route path="/website-development" element={<WebsiteDevelopment />} />
        <Route path="/digital-security" element={<DigitalSecurity />} />
        <Route path="/data-intelligence" element={<DataIntelligenceInner />} />
        <Route path="/cloud-management" element={<CloudManagement />} />
        <Route path="/impressum" element={<ImpressumInner />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/products-designer" element={<ProductsDesignerInner />} />
        <Route path="/machine-learning" element={<MachineLearningDetail />} />
        <Route
          path="/creative-content-design"
          element={<CreativeContentDesign />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
