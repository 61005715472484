import React from 'react'

const ContactBanner = () => {
    return (
        <div className='row align-items-end career-banner-container'>
            <div className="col-xl-5">
                <h1 className='career-banner-title'>Get In <br /> <span className='underline-image'>Touch</span> with <br /> Admirise</h1>
            </div>
            <div className="col-xl-7 mt-xl-0 mt-3">
                <p className='career-banner-text'>Get in touch with Admirise for all your digital marketing, information technology & Data Science needs in e-commerce!</p>
            </div>
        </div>
    )
}

export default ContactBanner;