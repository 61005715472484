import React from 'react'
import { Link } from 'react-router-dom'

const DataIntelligenceBanner = () => {
    return (
        <div className="container digital-marketing-banner">
            <div className='row align-items-center'>
                <div className="col-xl-4">
                    <h1 className='career-banner-title'><span className='data-int-span py-4 px-1'>Data</span> Intelligence</h1>
                </div>
                <div className="col-xl-8 mt-xl-0 mt-4">
                    <p className='career-banner-text'>
                        Data intelligence is so important to reach the success of your online business. That's why we offer a comprehensive data intelligence service to help you understand your data and make better business decisions.
                    </p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataIntelligenceBanner;