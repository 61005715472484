import React from 'react'
import Img from "../../assets/img/data-int-big.png"
import Plus from "../../assets/img/plus.png"

const DataIntelligenceBigCard = () => {
    return (
        <div className='container'>
            <div className="data-int-big-card">
                <img src={Img} alt="" width="100%" className='data-int-big-img' />
                <div className="data-int-card-body">
                    <div className='d-flex align-items-start data-ing-container'>
                        <img src={Plus} alt="" />
                        <h2 className='ps-3 text-white' style={{ fontWeight: "400" }}>Organize and Manage Your Data with Ease, Using Admirise’s Tailored Data Warehouse Design and Management Service</h2>
                    </div>
                    <div className='d-flex align-items-start data-ing-container mt-5'>
                        <img src={Plus} alt="" />
                        <h2 className='ps-3 text-white' style={{ fontWeight: "400" }}>Insightful and Result-Oriented Decisions with Data Intelligence</h2>
                    </div>
                    <div className='d-flex align-items-start data-ing-container mt-5'>
                        <img src={Plus} alt="" />
                        <h2 className='ps-3 text-white' style={{ fontWeight: "400" }}>Unlock The Insights Hidden in Your Data with Our Data Intelligence Service through Analysis and Visualisation</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataIntelligenceBigCard