import React from 'react'
import { Link } from 'react-router-dom'

const WebsiteBanner = () => {
    return (
        <div className="container digital-marketing-banner">
            <div className='row'>
                <div className="col-xl-5">
                    <h1 className='career-banner-title'><span className='website-span'>Website</span> <span className='website-span'>Development</span></h1>
                </div>
                <div className="col-xl-7 mt-xl-0 mt-4">
                    <p className='career-banner-text'>We know the significance of a high-quality website for the success of your e-commerce agency at Admirise. Our website development experts provide a range of services to help you create a website tailored to your specific needs and goals.</p>
                    <div className="contact-form-submit-btn">
                        <Link to='/contact'>
                            <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                                Contact Us <i className="fa-solid fa-arrow-right ms-2" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebsiteBanner