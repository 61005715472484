import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div id='impressum-container'>
            <div className="container">
                <div className='d-flex justify-content-between'>
                    <div>
                        <h1 style={{ marginBottom: "100px" }}>Product <br />Designer</h1>
                    </div>
                    <div className='text-end products-designer-banner-right'>
                        <button className="btn btn-outline-blue py-3 px-3 buttonfx curtaindown">
                            Apply <i className="fa-solid fa-arrow-right ms-2"></i>
                        </button>
                        <div className='d-flex mt-5'>
                            <div className="badge-blue">Hybrid</div>
                            <div className="badge-blue ms-3">Full time</div>
                        </div>
                    </div>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Key Responsibilities</h2>
                    <ul className='mt-4'>
                        <li>Work closely with product managers, engineers, and stakeholders to understand business goals, user needs, and technical requirements.</li>
                        <li>Conduct user research and usability testing to identify user needs, pain points, and preferences.</li>
                        <li>Create design concepts, wireframes, and prototypes that effectively communicate product functionality and user experience.</li>
                        <li>Design user interfaces and interactions that are intuitive, engaging, and visually appealing.</li>
                        <li>Collaborate with developers to ensure that designs are implemented accurately and efficiently.</li>
                        <li>Continuously iterate and refine designs based on user feedback, data analysis, and industry trends.</li>
                        <li>Stay up-to-date with the latest design trends, tools, and technologies, and share knowledge with the team.</li>
                    </ul>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Qualifications</h2>
                    <ul className='mt-4'>
                        <li>Bachelor's degree in Graphic Design, Interaction Design, or a related field.</li>
                        <li>Minimum of [insert number of years] years of experience in product design, UX design, or a related field.</li>
                        <li>Strong portfolio demonstrating expertise in product design, user-centered design, and interaction design.</li>
                        <li>Proficiency in design tools such as Sketch, Figma, or Adobe Creative Suite.</li>
                        <li>Experience conducting user research, usability testing, and data analysis.</li>
                        <li>Excellent communication, collaboration, and problem-solving skills.</li>
                        <li>Ability to manage multiple projects and priorities in a fast-paced environment.</li>
                        <li>Passion for designing products that solve real-world problems and improve people's lives.</li>
                    </ul>
                </div>
                <div className='impressum-item'>
                    <p className='mb-1'>If you meet the above qualifications and are excited about the opportunity to join a dynamic and growing team, we encourage you to submit your application. We offer competitive compensation packages, comprehensive benefits, and opportunities for career advancement.</p>
                </div>
                <div className="contact-form-submit-btn">
                    <button className="btn btn-outline-blue py-3 px-4 buttonfx curtaindown mt-5">
                        Apply <i className="fa-solid fa-arrow-right ms-2"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;