import React from 'react'
import WebsiteBanner from '../components/Services/WebsiteBanner'
import WebsiteQuote from '../components/Services/WebsiteQuote'
import WebsiteRowCard from '../components/Services/WebsiteRowCard'
import WebsiteColCard from '../components/Services/WebsiteColCard'
import WebsiteAccordion from '../components/Services/WebsiteAccordion'

const WebsiteDevelopment = () => {
    return (
        <div id='website-development-container' className='p-xl-0 p-3'>
            <WebsiteBanner />
            <WebsiteQuote />
            <WebsiteRowCard />
            <WebsiteColCard />
            <WebsiteAccordion />
        </div>
    )
}

export default WebsiteDevelopment;