import React from 'react'
import Image from '../../assets/img/services-d-m.png'

const DigitalMarketingBannerTwo = () => {
    return (
        <div className='py-5'>
            <img src={Image} alt="image" width='100%' />
        </div>
    )
}

export default DigitalMarketingBannerTwo