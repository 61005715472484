import React from 'react'
import CloudManagementBanner from '../components/Services/CloudManagementBanner';
import CloudManagementOptimize from '../components/Services/CloudManagementOptimize';
import CloudManagementSeamless from '../components/Services/CloudManagementSeamless';
import CloudManagementServices from '../components/Services/CloudManagementServices';
import CloudManagementCards from '../components/Services/CloudManagementCards';
import CloudManagementAccordion from '../components/Services/CloudManagementAccordion';

const CloudManagement = () => {
    return (
        <div id='cloud-management-container' className='p-xl-0 p-3'>
            <CloudManagementBanner />
            <CloudManagementOptimize />
            <CloudManagementSeamless />
            <CloudManagementServices />
            <CloudManagementCards />
            <CloudManagementAccordion />
        </div>
    )
}

export default CloudManagement;