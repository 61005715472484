import React from 'react'
import Img from "../../assets/img/data-int-small-1.png"
import Img2 from "../../assets/img/data-int-small-2.png"
import Line from "../../assets/img/line-blue.png"

const DataIntelligenceNumbers = () => {
    return (
        <div className="container">
            <div className='row align-items-center data-int-numbers-card'>
                <div className="col-xl-4">
                    <img src={Img} alt="" width="100%" />
                </div>
                <div className="col-xl-8">
                    <div>
                        <div className='d-flex align-items-center'>
                            <h2 className='text-blue'>01</h2>
                            <img src={Line} alt="" className='ms-4' />
                        </div>
                        <p style={{ fontSize: "22px", fontWeight: "500" }}>Transform Your Digital Business by Optimizing Performance and Driving Sales Through Personalization and A/B Testing.</p>
                    </div>
                    <div className='mt-5'>
                        <div className='d-flex align-items-center'>
                            <h2 className='text-blue'>02</h2>
                            <img src={Line} alt="" className='ms-4' />
                        </div>
                        <p style={{ fontSize: "22px", fontWeight: "500" }}>Improve Your Customer Experience and Increase Your Overall Competitiveness in the Market.</p>
                    </div>
                </div>
            </div>
            <div className='row align-items-center mt-5'>
                <div className="col-xl-8">
                    <div>
                        <div className='d-flex align-items-center'>
                            <h2 className='text-blue'>03</h2>
                            <img src={Line} alt="" className='ms-4' />
                        </div>
                        <p style={{ fontSize: "22px", fontWeight: "500" }}>Ensure the Integrity and Reliability of Your Data with Our Data Governance and Management Services.</p>
                    </div>
                    <div className='mt-5'>
                        <div className='d-flex align-items-center'>
                            <h2 className='text-blue'>04</h2>
                            <img src={Line} alt="" className='ms-4' />
                        </div>
                        <p style={{ fontSize: "22px", fontWeight: "500" }}>Stay on Top of Your Data with <span className='text-blue'>Admirise</span></p>
                    </div>
                </div>
                <div className="col-xl-4">
                    <img src={Img2} alt="" width="100%" />
                </div>
            </div>
        </div>
    )
}

export default DataIntelligenceNumbers;