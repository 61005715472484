import React from "react";
import { useEffect, useState } from "react";
import "./index.scss";

const BannerSlider = () => {
  const [subTitles, setSubtitles] = useState([
    "Cloud Management",
    "Digital Marketing",
    "E-Commers Management",
  ]);
  const [textPositionX, setTextPositionX] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [titleBgPosition, setTitleBgPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (textPositionX > -800) {
        setTextPositionX((prev) => prev - 400);
      } else if (textPositionX <= 800) {
        setTextPositionX(0);
      }
      setRotate((prev) => prev + 90);
      setTitleBgPosition((prev) => prev - 200);
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <section className="slider">
      <div className="slider-title">
        <h1>
          <span style={{ backgroundPosition: `${titleBgPosition}% center` }}>
            Creative
          </span>{" "}
          <br /> Digital Solitions
        </h1>
        <div className="slider-subtitle">
          <p>Admirise Ecommerce Marketing Agency & Digital Solutions</p>
        </div>
      </div>
      <div className="animation-area">
        <div className="slider-text">
          {subTitles.map((title, index) => {
            return (
              <p
                style={{ transform: `translateX(${textPositionX}px)` }}
                key={index}
              >
                {title}
              </p>
            );
          })}
        </div>
        <div className="slider-circle">
          <div className="colorfull-bg">
            <div className="slider-circle-inner">
              <div className="text-in-circle">
                <p>
                  360° <br /> E-commerce
                </p>
              </div>
              <div
                style={{ transform: `rotate(${rotate}deg)` }}
                className="lines"
              >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSlider;
