import React, { useState, useRef } from 'react'
import LineBlack from '../assets/img/line-black.png'
import DownArrow from '../assets/img/arrow-down-black.png'
import Logo from '../assets/img/footer-logo.png'
import Pin from '../assets/img/pin-outline.png'
import Call from '../assets/img/call.png'
import Mail from '../assets/img/mail-outline.png'
import Linkedin from '../assets/img/linkedin.png'
import Twitter from '../assets/img/twitter.png'
import Instagram from '../assets/img/instagram.png'
import { Routes, Route, Link, NavLink } from "react-router-dom"

const Footer = () => {
    const [see, setSee] = useState(false)
    const ref = useRef();
    const ref2 = useRef();

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    const seeMore = (e) => {
        setSee(true)
        ref.current.className = 'd-none'
        ref2.current.className = 'd-none'
    }

    return (
        <div id='footer' className='py-5'>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4">
                        <Link to='/' onClick={scrollTop}>
                            <img src={Logo} alt="logo" />
                        </Link>
                        <p className="mb-0 mt-5 footer-text">Admirise GmbH - © Copyright 2023. All rights reserved.</p>
                    </div>
                    <div className="col-xl-3 mt-xl-0 mt-4">
                        <h5 className="mb-3">Services</h5>
                        <div>
                            <div className="mb-1">
                                <NavLink to='/digital-marketing' className="text-dark" onClick={scrollTop}>
                                    <img src={LineBlack} alt="line" />
                                    <span className="mb-0">Digital Marketing</span>
                                </NavLink>
                            </div>
                            <div className="mb-1">
                                <NavLink to='/e-commerce-management' className="text-dark" onClick={scrollTop}>
                                    <img src={LineBlack} alt="line" />
                                    <span className="mb-0">E-commerce Management</span>
                                </NavLink>
                            </div>
                            <div className="mb-1">
                                <NavLink to='/creative-content-design' className="text-dark" onClick={scrollTop}>
                                    <img src={LineBlack} alt="line" />
                                    <span className="mb-0">Creative Content Design</span>
                                </NavLink>
                            </div>
                            <div className="mb-1">
                                <NavLink to='/website-development' className="text-dark" onClick={scrollTop}>
                                    <img src={LineBlack} alt="line" />
                                    <span className="mb-0">Website Development</span>
                                </NavLink>
                            </div>
                            <div onClick={seeMore} style={{ cursor: 'pointer' }}>
                                <img ref={ref} src={DownArrow} alt="arrow-down" />
                                <span ref={ref2} className="mb-0">See More</span>
                            </div>
                            {
                                see ?
                                    <>
                                        <div className="mb-1">
                                            <NavLink to='/digital-security' className="text-dark" onClick={scrollTop}>
                                                <img src={LineBlack} alt="line" />
                                                <span className="mb-0">Digital Security</span>
                                            </NavLink>
                                        </div>
                                        <div className="mb-1">
                                            <NavLink to='/data-intelligence' className="text-dark" onClick={scrollTop}>
                                                <img src={LineBlack} alt="line" />
                                                <span className="mb-0">Data Intelligence</span>
                                            </NavLink>
                                        </div>
                                        <div className="mb-1">
                                            <NavLink to='/cloud-management' className="text-dark" onClick={scrollTop}>
                                                <img src={LineBlack} alt="line" />
                                                <span className="mb-0">Cloud Management</span>
                                            </NavLink>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="col-xl-2 mt-xl-0 mt-4">
                        <h5 className="mb-3">Contact</h5>
                        <div>
                            <div className="mb-3">
                                <img src={Pin} alt="pin" />
                                <span className="mb-0">Berner Straße 109, 60437 Frankfurt am Main</span>
                            </div>
                            <div className="mb-3">
                                <img src={Call} alt="call" />
                                <span className="mb-0">+49 069 17427233</span>
                            </div>
                            <div>
                                <img src={Mail} alt="mail" />
                                <span className="mb-0">info@admirise.com</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 mt-xl-0 mt-4">
                        <h5 className="mb-3">Social</h5>
                        <div>
                            <div className="mb-2">
                                <img src={Linkedin} alt="linkedin" />
                                <span className="mb-0">Linkedin</span>
                            </div>
                            <div className="mb-2">
                                <img src={Twitter} alt="twitter" />
                                <span className="mb-0">Twitter</span>
                            </div>
                            <div>
                                <img src={Instagram} alt="instagram" />
                                <span className="mb-0">Instagram</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-1 mt-xl-0 mt-4">
                        <NavLink to='/privacy-policy' className="text-dark" onClick={scrollTop}>
                            <h5>Privacy &amp; Policy</h5>
                        </NavLink>
                        <NavLink to='/impressum' className="text-dark" onClick={scrollTop}>
                            <h5>Impressum</h5>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer