import React from 'react'
import { Link } from 'react-router-dom'
import Line from '../../assets/img/line.png'

const DigitalMarketingGetTouch = () => {
    return (
        <div className='row gx-0'>
            <div className="col-xl-12">
                <div className="comprehensive-card">
                    <div className="container">
                        <div className="row align-items-end justify-content-between">
                            <div className="col-xl-4">
                                <h2 className='mb-0 text-white'>Comprehensive Digital Marketing Services for All Business</h2>
                            </div>
                            <div className="col-xl-7">
                                <img src={Line} alt="line" width="175px" height='2px' />
                                <p className='mb-0 text-white' style={{ fontSize: '20px' }}>We offer comprehensive digital marketing services for all sizes of businesses to help reach their target audience, drive conversions, and increase revenues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <Link to='/contact'>
                    <div className="get-in-touch-card">
                        GET IN <br />TOUCH
                    </div>
                </Link>
            </div>
            <div className="col-xl-8">
                <div className="get-in-touch-right-card">

                </div>
            </div>
        </div>
    )
}

export default DigitalMarketingGetTouch;