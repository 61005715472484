import React from 'react'

const ImpressumInner = () => {
    return (
        <div id='impressum-container'>
            <div className="container">
                <h1 style={{marginBottom: "100px"}}>Impressum</h1>
                <div className='impressum-item'>
                    <h2 className="text-blue">Admirise GmbH</h2>
                    <p className='mb-1'>Berner Straße 109</p>
                    <p className='mb-1'>60437 Frankfurt am Main</p>
                    <p className='mb-1'>https://www.admirise.com</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Sitz der GmbH</h2>
                    <p className='mb-1'>Frankfurt am Main</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Registergericht</h2>
                    <p className='mb-1'>Amtsgericht Frankfurt am Main</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Handelsregisternummer</h2>
                    <p className='mb-1'>HRB 126268</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Ust-IdNr</h2>
                    <p className='mb-1'>DE350097354</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Geschäftsführer</h2>
                    <p className='mb-1'>Dr. Süleyman Demirci</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Kontakt</h2>
                    <p className='mb-1'>+496917427233</p>
                    <p className='mb-1'>info@admirise.com</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Öffnungszeiten</h2>
                    <p className='mb-1'>Mo.-Fr, 08.30 - 17.30 Uhr</p>
                </div>
                <div className='impressum-item'>
                    <h2 className="text-blue">Streitbeilegung</h2>
                    <p className='mb-1'>Die EU-Kommission hat unter http://ec.europa.eu/consumers/odr/ eine Plattform für eine außergerichtliche Streitschlichtung bereitgestellt.Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch dazu verpflichtet.</p>
                </div>
            </div>
        </div>
    )
}

export default ImpressumInner;